import { BotService } from "@/services";

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import Hashids from "hashids";
let hashids = new Hashids("", 6);
const lennaBotId = cookies.get("lenna_bot_id");

const Types = {
  SET_BOT_ID: "SET_BOT_ID",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_LOADING: "SET_LOADING",
  SET_BOT_LIST: "SET_BOT_LIST",
};

const state = {
  botId: lennaBotId || null,
  error: null,
  loading: false,
  botList: [],
};

const getters = {
  botId(state) {
    return {
      hashed: state.botId,
      decoded: state.botId !== null ? hashids.decode(state.botId)[0] : null,
    };
  },
  botList(state) {
    return state.botList;
  },
};

const mutations = {
  [Types.SET_BOT_ID]: (state, payload) => (state.botId = payload),
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.ERROR_SERVICE]: (state, payload) => (state.error = payload),
  [Types.SET_BOT_LIST]: (state, payload) => (state.botList = payload),
};

const actions = {
  setBotId({ commit }, payload) {
    cookies.set("lenna_bot_id", payload);
    commit(Types.SET_BOT_ID, payload);
  },
  async getBotList({ commit }) {
    const { data: response } = await BotService.getBot();

    // let noBot = {
    //   id: 0,
    //   name: "No Bot",
    // };
    // let bots = [noBot, ...response.data.bot];
    let bots = [...response.data.bot];

    console.log("BotsList", bots);
    commit(Types.SET_BOT_LIST, bots);
  },
  async getActiveBot({ dispatch }, payload) {
    const { data: response } = await BotService.getActiveBot(payload);
    // const response = await this.$axios.get("/bot-active?appId=" + payload);
    console.log("response", response);
    if (response.data.bot) {
      dispatch("setBotId", response.data.bot.hashed_id);
    } else {
      cookies.remove("lenna_bot_id");
    }
  },
  async create({ dispatch }, payload) {
    const { data: response } = await BotService.createBot(payload);
    dispatch("getBotList");
    return response.data;
  },
  async update({ dispatch }, payload) {
    const { data: response } = await BotService.updateBot(payload);
    dispatch("getBotList");
    return response.data;
  },
  async delete({ dispatch }, payload) {
    const { data: response } = await BotService.deleteBot(payload.id);
    dispatch("getBotList");
    return response.data;
  },
  async activate({ dispatch }, payload) {
    const { data: response } = await BotService.activateBot(payload);
    dispatch("getBotList");
    return response.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
