import { StoryService } from "@/services";
import { toArray } from "lodash";

const Types = {
  SET_STORIES_PAGINATE: "SET_STORIES_PAGINATE",
  SET_STORIES: "SET_STORIES",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_LOADING: "SET_LOADING",
  SET_TRASH_BIN_LIST: "SET_TRASH_BIN_LIST",
};

const state = {
  data: [],
  error: null,
  loading: false,
  trashBinList: [],
  stories: [],
};

const getters = {
  get(state) {
    return state.data;
  },
  trashBinList(state) {
    return state.trashBinList;
  },
  stories(state) {
    return state.stories;
  },
};

const mutations = {
  [Types.SET_STORIES_PAGINATE]: (state, payload) => (state.data = payload),
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.ERROR_SERVICE]: (state, payload) => (state.error = payload),
  [Types.SET_TRASH_BIN_LIST]: (state, payload) =>
    (state.trashBinList = toArray(payload)),
  [Types.SET_STORIES]: (state, payload) => (state.stories = payload),
};

const actions = {
  async getStoriesPaginate({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await StoryService.getStoriesPaginate(
        payload.sort,
        payload.page,
        payload.perPage
      );
      commit(Types.SET_STORIES_PAGINATE, response.data.data);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async create({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      await StoryService.createStory(payload.group, payload.title);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async delete({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      await StoryService.deleteStory(payload);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getTrashBin({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      let response = await StoryService.getTrashBinList();
      let data = response.data.data;
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        element.name = element.title;
      }
      commit(Types.SET_TRASH_BIN_LIST, data);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async restoreTrashBin({ commit }, id) {
    commit(Types.SET_LOADING, true);
    try {
      await StoryService.restoreTrashBin(id);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getStories({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await StoryService.getStories();
      commit(Types.SET_STORIES, response.data);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
