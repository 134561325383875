export const REQUEST_SEGMENT = "REQUEST_SEGMENT";
export const RECEIVE_FETCH_SEGMENT_SUCCESS = "REQUEST_FETCH_SEGMENT_SUCCESS";
export const RECEIVE_FETCH_SEGMENT_ERROR = "RECEIVE_FETCH_SEGMENT_ERROR";

export const RECEIVE_CREATE_SEGMENT_SUCCESS = "RECEIVE_CREATE_SEGMENT_SUCCESS";
export const RECEIVE_CREATE_SEGMENT_ERROR = "RECEIVE_CREATE_SEGMENT_ERROR";

export const RECEIVE_DELETE_SEGMENT_SUCCESS = "RECEIVE_DELETE_SEGMENT_SUCCESS";
export const RECEIVE_DELETE_SEGMENT_ERROR = "RECEIVE_DELETE_SEGMENT_ERROR";

export const REQUEST_UPDATE_SEGMENT = "REQUEST_UPDATE_SEGMENT";
export const RECEIVE_UPDATE_SEGMENT_SUCCESS = "RECEIVE_UPDATE_SEGMENT_SUCCESS";
export const RECEIVE_UPDATE_SEGMENT_ERROR = "RECEIVE_UPDATE_SEGMENT_ERROR";
