import { httpClientBot } from "@/libraries";

export default {
  async getStoriesPaginate(sort, page, perPage) {
    return await httpClientBot.get(
      `/stories?sort=${sort.field}|${sort.order}&page=${page}&per_page=${perPage}`
    );
  },
  async createStory(group, title) {
    return await httpClientBot.post("/stories/create", {
      group,
      title,
    });
  },
  async deleteStory(hashedId) {
    return await httpClientBot.delete(
      `/stories/massdelete?storyCheckBox=["${hashedId}"]`
    );
  },
  async getTrashBinList() {
    return await httpClientBot.get("/stories/trash");
  },
  async restoreTrashBin(id) {
    return await httpClientBot.post(`/stories/${id}/restore`);
  },
  async getStories() {
    return await httpClientBot.get("/stories");
  },
};
