<template>
  <b-modal
    hide-footer
    hide-header
    no-padding
    class="border-0"
    modal-class="lenna-modal"
    body-class="p-0 m-0 border-0"
    content-class="border-0"
    centered
    v-model="isOpen"
    v-bind="$attrs"
    @hide="handleCloseModal"
    @hidden="handleCloseModal"
  >
    <slot name="modal-header">
      <div
        class="ps-4 pe-3 py-2 bg-primary d-flex justify-content-between rounded-top"
      >
        <h5 class="modal-title text-light my-auto">{{ modalTitle }}</h5>
        <i
          class="ri-close-line text-white fs-4 pointer"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="handleCloseModal"
          v-if="!hideCloseIcon"
        ></i>
      </div>
    </slot>
    <form
      @submit.prevent="handleSubmit"
      style="overflow-y: auto; height: calc(100vh - 300px)"
    >
      <div class="container py-3 px-4" style="height: 100%">
        <div class="col-12 col-lg-12 col-lg-12">
          <div class="row">
            <small class="col-lg-12 col-12">
              Fill in the credentials below, you can get it from @botfather
              telegram bot
            </small>

            <h6 class="fw-3 mt-3">Bot Token</h6>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Bot Token"
                v-model="formData.botToken"
              />
              <b-button variant="outline-secondary" @click="getBotProfile">
                Check
              </b-button>
            </div>
            <div class="form-group mb-3">
              <h6 class="fw-3">Bot Name</h6>
              <input
                type="text"
                name="bot name"
                class="form-control"
                id="webhook-url"
                v-model="formData.botName"
                placeholder="Bot Name"
              />
            </div>
            <div class="form-group mb-3">
              <h6 class="fw-3">Bot Username</h6>
              <input
                type="text"
                name="bot username"
                class="form-control"
                id="webhook-url"
                v-model="formData.botUsername"
                placeholder="Bot Username"
              />
            </div>

            <input type="hidden" v-model="formData.id" />
            <input type="hidden" v-model="formData.status" />
          </div>
        </div>
        <div class="d-flex align-items-end flex-column">
          <base-button
            class="mt-auto p-2 modal-button-bottom"
            type="submit"
            :variant="'primary'"
            :loading="isLoading"
          >
            Submit
          </base-button>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { debounce, cloneDeep } from "lodash";
import vSelect from "vue-select";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
    },
    type: {
      type: String,
    },
    modalTitle: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    vSelect,
  },
  computed: {},
  data() {
    return {
      isOpen: this.showModal,
      securityTypes: ["SSL", "TLS"],
      securityType: null,
      formData: {},
    };
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.onShowModal();
      } else {
        this.onHideModal();
      }
    },
    form: {
      handler(newVal) {
        if (newVal) {
          this.formData = cloneDeep(newVal);
        }
      },
      immediate: true,
    },
    showModal(newVal) {
      if (newVal) {
        this.onShowModal();
      } else {
        this.onHideModal();
      }
    },
  },
  methods: {
    onShowModal() {
      this.isOpen = true;
    },
    onHideModal() {
      this.isOpen = false;
      this.$emit("onClose");
    },
    async getBotProfile() {
      try {
        let endpoint = "https://api.telegram.org/bot{bot-token}/getMe";
        endpoint = endpoint.replace("{bot-token}", this.formData.botToken);
        let response = await this.$axios.get(endpoint);
        response = response.data;

        this.formData.botName = response.result.first_name;
        this.formData.botUsername = response.result.username;
        console.log("Bot Telegram", this.formData.botUsername);
        return true;
      } catch (error) {
        this.errorToast("Unauthorized / invalid bot token");
        return false;
      }
    },
    handleCloseModal() {
      if (this.type == "create") {
        // this.formData.botToken = "";
        // this.formData.botName = "";
        // this.formData.botUsername = "";
      }
      this.$emit("onClose");
    },
    async handleSubmit() {
      await this.submit(this.formData, this);
    },
    submit: debounce(async (data, vm) => {
      vm.$emit("onSubmit", data);
    }, 300),

    handleCopy() {
      let copyCode = document.querySelector("#webhook-url");
      copyCode.setAttribute("type", "text");
      copyCode.select();
      document.execCommand("copy");
    },
  },
  mounted() {
    this.emitter.on("form:reset", () => {
      this.resetObjectValue(this.formData);
    });
  },
};
</script>

<style>
.img-prepend {
  position: relative;
  float: right;
  right: 15px;
  bottom: 28px;
  width: 20px;
  height: 20px;
}
.fw-3 {
  font-weight: 400;
  font-size: 9pt;
}
.fs-9 {
  font-size: 9pt;
}

.modal-button-bottom {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>
