<template>
  <div class="card border border-light" id="search-workspace">
    <div class="card-body py-0">
      <b-tabs
        pills
        card
        nav-class="pb-3"
        active-tab-class="pt-0"
        active-nav-item-class="rounded-pill"
      >
        <b-tab active title="All">
          <project :data="project"></project>
          <ticket></ticket>
          <customers></customers>
        </b-tab>
        <b-tab>
          <template #title> Project </template>
          <project :data="project"></project>
        </b-tab>
        <b-tab>
          <template #title> Tickets </template>
          <ticket></ticket>
        </b-tab>
        <b-tab>
          <template #title> Customers </template>
          <customers></customers>
        </b-tab>
      </b-tabs>
    </div>
    <!-- end card-body -->
  </div>
</template>
<script>
import Ticket from "@/views/auth/components/search/Tickets.vue";
import Project from "@/views/auth/components/search/Projects.vue";
import Customers from "@/views/auth/components/search/Customers.vue";

export default {
  props: {
    project: {
      required: true,
      type: Object,
    },
  },
  setup() {},
  components: {
    Project,
    Ticket,
    Customers,
  },
  data() {
    return {};
  },
  watch: {
    ticket() {
      return this.ticket.filter((o, key) => key < 3);
    },
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss">
#search-workspace {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #233361 !important;
    box-shadow: 5 5px 10px rgba(30, 32, 37, 0.12);
  }

  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 20rem;
  }
}
</style>
