import { BroadcastByChannel } from "@/services";

const Types = {
  SET_LOADING: "SET_LOADING",
};

const state = {
  data: [],
  error: null,
  loading: false,
};

const getters = {
  get: (state) => state.data,
};

const mutations = {
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
};

const actions = {
  async sendBroadcastLine({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await BroadcastByChannel.sendBroadcastLine({
        ...payload,
      });
      console.log("success broadcast by channel", response.data.state);
    } catch (err) {
      console.log("error broadcast by channel", err);
    }
    commit(Types.SET_LOADING, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
