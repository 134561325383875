import * as Types from "./mutation-types";

export default {
  [Types.SET_ERROR]: (state) => (state.isError = !state.isError),
  [Types.SET_LOADING]: (state) => (state.isLoading = !state.isLoading),
  [Types.RECEIVE_INTERACTION_SUCCESS]: (state, payload) =>
    (state.interactions = payload),
  [Types.RECEIVE_INTERACTION_LIST_SUCCESS]: (state, payload) =>
    (state.interactionList = payload),
  [Types.RECEIVE_FUNCTIONLIST_API_SUCCESS]: (state, payload) =>
    (state.functionlistApi = payload),
  [Types.RECEIVE_FUNCTIONLIST_FUNCTION_SUCCESS]: (state, payload) =>
    (state.functionlistFunction = payload),
  [Types.RECEVIE_ENTITIES_SUCCESS]: (state, payload) =>
    (state.entities = payload),
  [Types.RECEIVE_SYS_ENTITIES_SUCCESS]: (state, payload) =>
    (state.sysEntities = payload),
  [Types.RECEIVE_CHANNELS_SUCCESS]: (state, payload) =>
    (state.channels = payload),
  [Types.RECEIVE_STORY_GROUP_SUCCESS]: (state, payload) =>
    (state.storyGroup = payload),
  [Types.RECEIVE_STORY_SUCCESS]: (state, payload) => (state.story = payload),
  [Types.SET_INTERACTION]: (state, payload) =>
    (state.interactions = [...state.interactions, payload]),
  [Types.SET_MERGED_ENTITIES]: (state, payload) =>
    (state.mergedEntities = payload),
  [Types.SET_SELECTED_INTERACTION]: (state, payload) =>
    (state.selectedInteraction = payload),
  [Types.RECEIVE_STORIES_SUCCESS]: (state, payload) =>
    (state.stories = payload),
};
