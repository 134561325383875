export default [
  {
    path: "/:appId/omni/whatsapp",
    name: "omni.whatsapp",
    component: () => import("@/views/whatsapp/pages/WhatsappMain.vue"),
    redirect: { name: "whatsapp.broadcast" },
    props: true,
  },
  {
    path: "/:appId/omni/whatsapp/broadcast",
    name: "omni.whatsapp.broadcast",
    component: () => import("@/views/whatsapp/pages/WhatsappMain.vue"),
    props: true,
    meta: {
      title: "Whatsapp Broadcast",
    },
  },
  {
    path: "/:appId/omni/whatsapp/template",
    name: "omni.whatsapp.template",
    component: () => import("@/views/whatsapp/pages/WhatsappTemplate.vue"),
    meta: {
      title: "Whatsapp HSM Template",
    },
  },
  // {
  //   path: "/:appId/omni/broadcast-management",
  //   name: "whatsapp.broadcast-management",
  //   component: () => import("@/views/whatsapp/pages/WhatsappMain.vue"),
  // },
];
