import * as types from "./mutation_type";

export default {
  [types.REQUEST_SEGMENT]: (state) => {
    state.isLoading = true;
  },
  [types.RECEIVE_FETCH_SEGMENT_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    payload.map((each_segment) => {
      each_segment.query = JSON.parse(each_segment.query);
      return each_segment;
    });
    state.segments = payload;
  },
  [types.RECEIVE_FETCH_SEGMENT_ERROR]: (state) => {
    state.isLoading = false;
    state.hasError = true;
  },

  [types.RECEIVE_CREATE_SEGMENT_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.segments = [
      ...state.segments,
      { ...payload, query: JSON.parse(payload.query) },
    ];
  },
  [types.RECEIVE_CREATE_SEGMENT_ERROR]: (state) => {
    state.isLoading = false;
    state.hasError = true;
  },

  [types.RECEIVE_DELETE_SEGMENT_SUCCESS]: (state, segment_id) => {
    state.isLoading = false;
    state.segments = state.segments.filter(
      (each_segment) => each_segment.id !== segment_id
    );
  },
  [types.RECEIVE_DELETE_SEGMENT_ERROR]: (state) => {
    state.isLoading = false;
    state.hasError = true;
  },

  [types.RECEIVE_UPDATE_SEGMENT_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    // find the index of segmet
    let indexSegment = state.segments.findIndex(
      (each_segment) => each_segment.id == payload.id
    );
    state.segments[indexSegment] = { ...payload };
  },
  [types.RECEIVE_UPDATE_SEGMENT_ERROR]: (state) => {
    state.isLoading = false;
    state.hasError = true;
  },
};
