<template>
  <b-modal
    v-model="modalShow"
    :title="title"
    hide-footer
    modal-class="flip"
    class="v-modal-custom"
    @hide="handleCloseModal"
    @hidden="handleCloseModal"
    centered
  >
    <form action="javascript:void(0);">
      <b-row class="g-2">
        <!-- option integration start -->
        <div class="col-lg-12 mt-3">
          <div>
            <label for="topics" class="form-label">Topics</label>
            <input
              v-model="this.topics"
              type="text"
              class="form-control"
              id="topics"
              required
            />
          </div>
          <div>
            <label class="form-label">integration</label>
            <v-select
              label="label"
              name="integration"
              class="bg-white"
              :options="optionsIntegration"
              :placeholder="`Select your integration`"
              v-model="integrationModel"
              @option:selected="changeValueListIntegration"
            />
          </div>
          <!-- option integration end -->
          <div class="row mt-2">
            <div class="col">
              <b-button
                type="submit"
                variant="soft-success"
                class="w-100"
                @click="addForm('text')"
                ><i class="ri-image-edit-line"></i> Text</b-button
              >
            </div>
            <div class="col">
              <b-button
                type="submit"
                variant="soft-success"
                class="w-100"
                @click="addForm('image')"
                ><i class="ri-image-edit-line"></i> Image</b-button
              >
            </div>
            <!-- lets open this feature in future not now :) -->
            <!-- <div class="col">
              <b-button
                type="submit"
                variant="soft-success"
                class="w-100"
                @click="addForm('carousel')"
                ><i class="ri-image-edit-line"></i> Carousel</b-button
              >
            </div> -->
          </div>
          <!-- button add end -->
          <transition-group name="list" tag="form">
            <div v-for="(form, index) in forms" :key="index">
              <form v-if="form.type == 'text'">
                <div class="card mt-4">
                  <div class="card-header">
                    <h6 class="card-title fw-semibold mb-0">Message</h6>
                  </div>
                  <div class="card-body">
                    <textarea
                      class="form-control mt-2"
                      rows="4"
                      v-model="form.text"
                    ></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <b-button
                      type="submit"
                      variant="danger"
                      class="w-100"
                      @click.prevent="deleteForm(index)"
                      ><i class="ri-delete-bin-6-line"></i> Delete</b-button
                    >
                  </div>
                </div>
              </form>
              <form v-if="form.type == 'image'">
                <div class="card mt-4">
                  <div class="card-header">
                    <h6 class="card-title fw-semibold mb-0">
                      Image Attachment
                    </h6>
                  </div>

                  <div class="card-body">
                    <Uploader
                      @onAdd="addImage($event, index)"
                      id="addImage"
                      :label="'Drag & drop mage to upload'"
                    >
                    </Uploader>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <b-button
                      type="submit"
                      variant="danger"
                      class="w-100"
                      @click.prevent="deleteForm(index)"
                      ><i class="ri-delete-bin-6-line"></i> Delete</b-button
                    >
                  </div>
                </div>
              </form>
              <form v-if="form.type == 'carousel'" class="mt-2">
                <div class="card mt-4">
                  <div class="card-header">
                    <h6 class="card-title fw-semibold mb-0">carousel</h6>
                  </div>
                  <div class="card-body"></div>
                </div>
                <div class="row">
                  <div class="col">
                    <b-button
                      type="submit"
                      variant="danger"
                      class="w-100"
                      @click.prevent="deleteForm(index)"
                      ><i class="ri-delete-bin-6-line"></i> Delete</b-button
                    >
                  </div>
                </div>
              </form>
            </div>
          </transition-group>
          <!-- textarea message end -->
        </div>
        <!-- bottom start-->
        <b-col lg="12">
          <div class="hstack gap-2 justify-content-end">
            <b-button type="button" variant="light" @click="handleCloseModal">
              cancel</b-button
            >
            <b-button
              type="submit"
              variant="success"
              class="px-4"
              @click="handleSubmit"
              >Broadcast</b-button
            >
          </div>
        </b-col>
        <!-- bottom end-->
      </b-row>
    </form>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash"; // use this if you want to edit data from vuex.
import { useDropzone } from "vue3-dropzone"; // drop zone for image
import { httpClientOmni } from "@/libraries";
export default {
  components: {
    vSelect,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    title: {
      type: String,
      default: "Broadcast",
    },
  },
  setup() {
    function onDrop(acceptFiles, rejectReasons) {
      console.log(rejectReasons);
    }
    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  data() {
    return {
      modalShow: this.showModal,
      forms: [],
      integrationModel: null,
      topics: null,
    };
  },

  computed: {
    // when you try to use v-select ===================================================== ||
    // pls make sure you have key named label and value inside the json data ============ ||
    // if dont you can generate manually value and label using this func ================ ||
    // this func help us to restructure our json key pair =============================== ||
    // first this func will check is listIntegration empty or not ======================= ||
    // if not empty they will try to mapping new key pair for v-select options ========== ||
    optionsIntegration() {
      console.log("list integration");
      console.log(this.listIntegration);
      return this.listIntegration
        ? this.listIntegration.map((item) => ({
            value: item.id,
            label:
              item.integration_data.IntegrationName +
              " [" +
              (item.status ? "Active" : "Not Active") +
              "]",
          }))
        : [];
    },
    // dont forget this only getting data from vuex, ==================================== ||
    // make sure you call action to update this data before its show in modal box ======= ||
    ...mapGetters({
      listIntegration: "integration/integrationChannel",
    }),
  },
  watch: {
    // ================================================================================== ||
    // SHOW MODAL FUNC ================================================================== ||
    // first func running aftar modalbox trigged ======================================== ||
    showModal(newVal) {
      this.topics = null;
      this.integrationModel = null;
      this.modalShow = newVal;
      console.log("data form dari parent ", this.data);
      console.log("data list integration : ", this.listIntegration);
      if (this.data != null) {
        this.form = cloneDeep(this.data);
      }
    },
  },
  methods: {
    addForm(type) {
      if (this.forms.length > 4) {
        this.errorToast("Max 5 messages to send");
        return;
      }
      if (type === "text") {
        this.forms.push({ type: "text", text: "" });
      } else if (type === "image") {
        this.forms.push({ type: "image", url: "" });
      } else if (type === "carousel") {
        this.forms.push({ type: "carousel", items: [] });
      }
      console.log("form add : ", this.forms);
    },
    deleteForm(index) {
      this.forms.splice(index, 1);
    },
    // ================================================================================== ||
    // handleCloseModal FUNC ============================================================ ||
    handleCloseModal() {
      this.$emit("onClose"); // Emit an event named "onClose" to notify parent components
      this.modalShow = false; // Set the value of modalShow to false, hiding the modal
      this.forms = [];
    },
    // ================================================================================== ||
    // handleSubmit FUNC ================================================================ ||
    // The && operator short-circuits if the left operand is falsy, ===================== ||
    // so the $emit call is only executed if the validate method returns a truthy value = ||
    handleSubmit() {
      console.log("data hasil");
      let dataForm = {
        topics: this.topics,
        integration_id: this.form.integration_id ?? null,
        messages: this.forms,
      };
      console.log(dataForm);
      this.validate()
        ? this.$emit("onSubmit", dataForm)
        : this.errorToast("Please complete all required fields");
    },
    // ================================================================================== ||
    // validate FUNC ==================================================================== ||
    validate() {
      // this is shorthand ============================================================== ||
      // If all fields are truthy, the expression inside the !! operator ================ ||
      // evaluates to true, and !! converts it to the boolean value true. =============== ||
      // If any of the fields are falsy, the expression evaluates to false, ============= ||
      // and !! converts it to the boolean value false. ================================= ||
      return !!(this.form.integration_id && this.forms && this.topics);
    },
    // ================================================================================== ||
    // changeValueListIntegration FUNC ================================================== ||
    changeValueListIntegration(val) {
      this.integrationModel = val.label;
      this.form = {
        integration_id: val.value,
      };
    },

    async addImage(file, index) {
      let f = new FormData();
      f.append("file", file.file);
      f.append("channel", "line");
      let response = await httpClientOmni.post(
        "broadcast-by-channel/upload-image",
        f,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (!response) {
        this.errorToast("Upload image failed, try again!");
        return;
      }
      console.log("picture:", response);
      this.forms[index].url = response.data.image_url.original.data.fileUrl;
      console.log("form : ", this.forms);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
