s
<template>
  <b-modal
    hide-footer
    hide-header
    no-padding
    class="border-0"
    modal-class="lenna-modal"
    body-class="p-0 m-0 border-0"
    content-class="border-0"
    centered
    size="lg"
    v-model="isOpen"
    v-bind="$attrs"
    @hide="handleCloseModal"
    @hidden="handleCloseModal"
  >
    <form @submit.prevent="handleSubmit">
      <div
        id="modal-email"
        class="modal-content border-0 overflow-hidden"
        style="overflow-y: auto; height: calc(110vh - 300px)"
      >
        <div class="modal-header p-3">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            Integration E-mail
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4 border">
          <b-tabs
            pills
            card
            vertical
            active-nav-item-class="bg-primary rounded w-100 py-3"
            nav-wrapper-class="col-md-3 border-bottom-0"
            content-class="col-md-9 pt-3 bg-light h-100"
            nav-class="pb-3 pt-3"
            class="border h-100"
          >
            <b-tab title="Configuration" active>
              <div class="row g-2">
                <div class="col-lg-12 mb-3 px-3 bg-primary p-3 rounded">
                  <div>
                    <label class="form-label text-white">
                      Integration Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="username"
                      :placeholder="`Enter your username`"
                      autocomplete="off"
                      v-model="formData.username"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mb-3 px-3">
                  <div>
                    <label class="form-label"> Email Address </label>
                    <input
                      type="text"
                      class="form-control"
                      name="email_imap"
                      :placeholder="`Enter your email`"
                      autocomplete="off"
                      v-model="formData.email"
                    />
                  </div>
                </div>
                <!--end col-->
                <div class="col-lg-6 mb-3 px-3">
                  <div>
                    <label class="form-label">Password</label>
                    <template
                      v-if="type == 'create' || isResetPassword == true"
                    >
                      <div class="d-flex" v-if="isResetPassword">
                        <div class="input-group">
                          <input
                            type="password"
                            class="form-control"
                            name="password_imap"
                            :placeholder="`*******`"
                            autocomplete="off"
                            v-model="formData.password"
                          />
                          <button
                            @click.prevent="cancelResetPassword"
                            type="button"
                            class="btn btn-light"
                            id="button-addon2"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                      <div v-else>
                        <input
                          type="password"
                          class="form-control"
                          name="password_imap"
                          :placeholder="`*******`"
                          autocomplete="off"
                          v-model="formData.password"
                        />
                        <small class="text-danger">
                          {{ validatePassword }}
                        </small>
                      </div>
                    </template>
                    <div class="d-flex" v-else>
                      <button
                        @click.prevent="resetPassword"
                        type="button"
                        class="btn btn-danger w-100"
                      >
                        <i class="ri-refresh-line"></i>
                        Reset Password
                      </button>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-lg-6 mb-3 px-3">
                  <div>
                    <label class="form-label">Forward To</label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="`(Optional)`"
                        autocomplete="off"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        v-model="formData.forward_to"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2"
                          >@lenna.ai</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-3 px-3">
                  <div>
                    <label class="form-label">Business Unit</label>
                    <v-select
                      label="label"
                      name="bussines_unit"
                      class="bg-white hg-18"
                      :options="businessUnit"
                      :placeholder="`Select bussines unit`"
                      v-model="business_unit"
                      @option:selected="changeValueBusinessUnit"
                    />
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Incoming Server">
              <div class="row g-2">
                <div class="col-lg-12 mb-3 px-3">
                  <div>
                    <label class="form-label"> IMAP Server </label>
                    <input
                      type="text"
                      class="form-control"
                      name="imap_server"
                      :placeholder="`imap.gmail.com`"
                      v-model="formData.imap_server"
                    />
                  </div>
                </div>

                <div class="col-lg-6 mb-3 px-3">
                  <div>
                    <label class="form-label"> Security Type </label>
                    <v-select
                      label="name"
                      name="security_type"
                      class="bg-white hg-18"
                      :options="securityTypes"
                      @option:selected="onSelectSecurityType"
                      :placeholder="`Select security type`"
                      v-model="securityType"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="col-lg-6 mb-3 px-3" v-if="isSecurityTypeOtherImap">
                  <label class="form-label">&nbsp;</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      name="security_type"
                      :placeholder="`Enter security type`"
                      v-model="imapSecurityTypeOther"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mb-3 px-3">
                  <div>
                    <label class="form-label"> Port </label>
                    <input
                      type="text"
                      class="form-control disabled"
                      name="imap_port"
                      :placeholder="`Enter port`"
                      v-model="formData.imap_port"
                    />
                  </div>
                </div>
                <div class="col-lg-6 mb-3 px-3">
                  <div>
                    <label class="form-label"> Path Prefix </label>
                    <input
                      type="text"
                      class="form-control"
                      name="imap_path_prefix"
                      :placeholder="`Optional`"
                      v-model="formData.imap_path_prefix"
                    />
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Outgoing Server">
              <div class="row g-2">
                <div class="col-lg-12 mb-3 px-3">
                  <div>
                    <label class="form-label"> SMTP Server </label>
                    <input
                      type="text"
                      class="form-control"
                      name="smtp_server"
                      :placeholder="`smtp.gmail.com`"
                      v-model="formData.smtp_server"
                    />
                  </div>
                </div>

                <div class="col-lg-6 mb-3 px-3">
                  <div>
                    <label class="form-label"> Security Type </label>
                    <v-select
                      label="name"
                      name="security_type"
                      class="bg-white hg-18"
                      :options="securityTypes"
                      @option:selected="onSmtpSelectSecurityType"
                      :placeholder="`Select security type`"
                      v-model="smtpSecurityType"
                    >
                    </v-select>
                  </div>
                </div>

                <div class="col-lg-6 mb-3 px-3" v-if="isSecurityTypeOtherSmtp">
                  <label class="form-label">&nbsp;</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      name="security_type"
                      :placeholder="`Enter security type`"
                      v-model="smtpSecurityTypeOther"
                    />
                  </div>
                </div>

                <div class="col-lg-6 mb-3 px-3">
                  <div>
                    <label class="form-label"> Port </label>
                    <input
                      type="text"
                      class="form-control"
                      name="smtp_port"
                      :placeholder="`Enter port`"
                      v-model="formData.smtp_port"
                    />
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Setting">
              <div class="row g-2">
                <div class="col-lg-12 mb-3 px-3">
                  <div>
                    <div class="form-check form-switch mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="formData.is_auto_create_ticket"
                      />
                      <label class="form-check-label">Auto create ticket</label>
                    </div>
                    <div class="form-check form-switch mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="formData.is_auto_reply"
                      />
                      <label class="form-check-label">Auto reply </label>
                    </div>
                    <div class="form-check form-switch mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="formData.is_auto_delete"
                      />
                      <label class="form-check-label"
                        >Auto delete ticket (On Premise)</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div class="modal-footer p-4">
          <base-button
            type="submit"
            class="w-100"
            :variant="'primary'"
            :loading="isLoading"
          >
            Submit
          </base-button>
        </div>
      </div>
    </form>
    <!-- /.modal-content -->
  </b-modal>
</template>

<script>
import { debounce } from "lodash";
import vSelect from "vue-select";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
    },
    modalTitle: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    businessUnit: {
      type: Object,
    },
  },
  components: {
    vSelect,
  },
  computed: {
    formData() {
      return Object.assign({}, this.form);
    },
  },
  data() {
    return {
      isOpen: this.showModal,
      securityTypes: ["None", "SSL", "TLS", "STARTTLS", "Other"],
      securityType: null,
      isSecurityTypeOtherSmtp: false,
      isSecurityTypeOtherImap: false,
      smtpSecurityTypeOther: "",
      imapSecurityTypeOther: "",
      isResetPassword: false,
      validatePassword: "",
      business_unit: null,
      business_unit_id: null,
      next: "",
    };
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.onShowModal();
      } else {
        this.onHideModal();
      }
    },
    form(val) {
      if (val) {
        this.securityType = val.imap_security_type;
        this.smtpSecurityType = val.smtp_security_type;
        this.business_unit = val.business_unit
          ? val.business_unit.branch_office
          : null;
      }
    },
    showModal(newVal) {
      if (newVal) {
        this.onShowModal();
      } else {
        this.onHideModal();
      }
    },
  },
  methods: {
    onShowModal() {
      this.isOpen = true;
    },
    onHideModal() {
      this.isOpen = false;
      this.$emit("onClose");
    },
    changeValueBusinessUnit(val) {
      this.formData.business_unit_id = val.id;
      this.formData.business_unit = val.branch_office;
      this.business_unit = val.label;
    },
    resetPassword() {
      this.isResetPassword = true;
      this.formData.password = "";
    },
    cancelResetPassword() {
      this.isResetPassword = false;
    },
    onSelectSecurityType(val) {
      if (val == "Other") {
        this.isSecurityTypeOtherImap = true;
      } else {
        this.isSecurityTypeOtherImap = false;
      }

      this.formData.imap_security_type = val;
    },
    onSmtpSelectSecurityType(val) {
      this.smtpSecurityType = val;
      if (val == "Other") {
        this.isSecurityTypeOtherSmtp = true;
      } else {
        this.isSecurityTypeOtherSmtp = false;
      }

      this.formData.smtp_security_type = val;
    },
    handleCloseModal() {
      this.$emit("onClose");
    },
    async handleSubmit() {
      // if (this.$store.getters.appId.hashed == "PdyMgd") {
      //   let regexEmail =
      //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&^(){}])[A-Za-z\d@$!%*?&]{6,}$/;
      //   let validate = regexEmail.test(this.formData.password);
      //   if (!validate) {
      //     this.validatePassword =
      //       "Minimum 6 characters, at least one uppercase letter, one lowercase letter, one number and one special character";
      //     this.$refs.formIntegration.scrollTop = 0;
      //     this.next = false;
      //   } else {
      //     this.next = true;
      //   }
      // } else {
      //   this.next = true;
      // }
      this.next = true;

      if (this.next) {
        if (this.formData.imap_security_type == "Other") {
          this.formData.imap_security_type = this.imapSecurityTypeOther;
        }

        if (this.formData.smtp_security_type == "Other") {
          this.formData.smtp_security_type = this.smtpSecurityTypeOther;
        }
        this.isResetPassword = false;
        await this.submit(this.formData, this);
      }
    },
    submit: debounce(async (data, vm) => {
      vm.$emit("onSubmit", data);
    }, 300),
    handleCopy() {
      let copyCode = document.querySelector("#webhook-url");
      copyCode.setAttribute("type", "text");
      copyCode.select();
      document.execCommand("copy");
    },
  },
  mounted() {
    this.emitter.on("form:reset", () => {
      this.resetObjectValue(this.formData);
    });
  },
};
</script>

<style>
.img-prepend {
  position: relative;
  float: right;
  right: 15px;
  bottom: 28px;
  width: 20px;
  height: 20px;
}
.fw-3 {
  font-weight: 400;
  font-size: 9pt;
}
.fs-9 {
  font-size: 9pt;
}
</style>
<style lang="scss">
#modal-email {
  ul {
    margin-right: 0 !important;
    margin-left: 1px !important;
  }
  .nav-link {
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .nav-link li:hover {
    background: #0c1734;
    color: #fff;
  }

  .hg-18 input {
    height: 1.8rem !important;
  }
}
</style>
