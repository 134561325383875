const mailMain = [
  {
    path: "/:appId/mailbox",
    name: "mailbox",
    component: () => import("@/views/mail/pages/MailMain.vue"),
    meta: { title: "Mail List" },
    redirect: {
      name: "mailbox.main",
    },
  },
  {
    path: "/:appId/mailbox/main",
    name: "mailbox.main",
    component: () => import("@/views/mail/pages/MailMain.vue"),
    meta: { title: "Mailbox" },
  },
];
export default mailMain;
