<template>
  <select class="form-control" @change="handleSelectChange" :value="value">
    <option value="" disabled selected>{{ optionDefault }}</option>
    <option
      v-for="(option, i) in options"
      :key="i"
      :selected="value == option.name"
    >
      {{ option.name }}
    </option>
  </select>
</template>
<script>
export default {
  name: "BaseSelect",
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    optionDefault: {
      type: String,
      default: "Select your option"
    }
  },
  methods: {
    handleSelectChange(e) {
      this.$emit("input", e.target.value);
    }
  }
};
</script>
