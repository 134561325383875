import state from "./state";
import mutations from "./mutation";
import * as actions from "./action";
import * as getters from "./getters";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
