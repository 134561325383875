export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_INTERACTION = "SET_INTERACTION";
export const SET_MERGED_ENTITIES = "SET_MERGED_ENTITIES";

export const RECEIVE_INTERACTION_SUCCESS = "RECEIVE_INTERACTION_SUCCESS";

export const RECEIVE_FUNCTIONLIST_API_SUCCESS =
  "RECEIVE_FUNCTIONLIST_API_SUCCESS";
export const RECEIVE_FUNCTIONLIST_FUNCTION_SUCCESS =
  "RECEIVE_FUNCTIONLIST_FUNCTION_SUCCESS";

export const RECEVIE_ENTITIES_SUCCESS = "RECEVIE_ENTITIES_SUCCESS";
export const RECEIVE_SYS_ENTITIES_SUCCESS = "RECEIVE_SYS_ENTITIES_SUCCESS";

export const RECEIVE_CHANNELS_SUCCESS = "RECEIVE_CHANNELS_SUCCESS";
export const RECEIVE_STORY_GROUP_SUCCESS = "RECEIVE_STORY_GROUP_SUCCESS";
export const RECEIVE_STORY_SUCCESS = "RECEIVE_STORY_SUCCESS";

export const SET_SELECTED_INTERACTION = "SET_SELECTED_INTERACTION";

export const RECEIVE_STORIES_SUCCESS = "RECEIVE_STORIES_SUCCESS";

export const RECEIVE_INTERACTION_LIST_SUCCESS =
  "RECEIVE_INTERACTION_LIST_SUCCESS";
