import { httpClientOmni } from "@/libraries";

export default {
  async updateAvatar(data) {
    return await httpClientOmni.post("/avatar", data);
  },
  async removeFile(data) {
    return await httpClientOmni.post("webchat-style/remove-file", {
      fileToDelete: data,
    });
  },
  async getStyle(payload) {
    return await httpClientOmni.get("webchat-style", {
      params: { integrationId: payload },
    });
  },
};
