import { httpClientOmni } from "@/libraries";

export default {
  async getWaUserList(value) {
    return await httpClientOmni.get(`/user/get-wa-user-list?filter=${value}`);
  },
  async getUserListByEmail(value) {
    return await httpClientOmni.get(`/user/get-user-list-by-email`, value);
  },
};
