import { GroupService } from "@/services";

const Types = {
  FETCH_GROUPS_SUCCESS: "FETCH_GROUPS_SUCCESS",
  FETCH_AGENTS_SUCCESS: "FETCH_AGENTS_SUCCESS",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_LOADING: "SET_LOADING",
};

const state = {
  agents: [],
  data: [],
  error: null,
  loading: false,
};

const getters = {
  get(state) {
    return state.data;
  },
  agents(state) {
    return state.agents;
  },
};

const mutations = {
  [Types.FETCH_GROUPS_SUCCESS]: (state, payload) => (state.data = payload),
  [Types.CREATE_TAG]: (state, payload) =>
    (state.data = [...state.data, payload]),
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.NEW_TAG]: (state, payload) => (state.data = [...state.data, payload]),
  [Types.FETCH_AGENTS_SUCCESS]: (state, payload) => (state.agents = payload),
};

const actions = {
  async get({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await GroupService.get();
      commit(Types.FETCH_GROUPS_SUCCESS, response);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getGroupOnly({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await GroupService.findGroupOnly(data);
      commit(Types.FETCH_GROUPS_SUCCESS, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getGroupWithAgent({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await GroupService.getGroupWithAgent(data);
      commit(Types.FETCH_GROUPS_SUCCESS, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getGroupAgent({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await GroupService.getGroupAgent();
      commit(Types.FETCH_AGENTS_SUCCESS, response);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
