import forEach from "lodash/forEach";

const state = {
  menus: [],
  modules: [],
  isSidebar: false,
};
const getters = {
  menus(state) {
    return state.menus;
  },
  modules(state) {
    return state.modules;
  },
  isSidebar(state) {
    return state.isSidebar;
  },
};
const mutations = {
  SET_MENUS: (state, payload) => {
    state.menus = payload;
  },
  SET_MODULES: (state, payload) => {
    state.modules = payload;
  },
  UPDATE_MODULES: (state, payload) => {
    console.log("update module");
    const data = forEach(state.modules, function (o) {
      if (o.slug == payload.slug) {
        o.url = payload.url;
        o.route = payload.url;
      }
    });
    state.modules = data;
  },
  SET_SIDEBAR: (state, payload) => {
    state.isSidebar = payload;
  },
};
const actions = {
  async getMenus(context) {
    let response = await this.$axios.get("api/menus");
    context.commit("SET_MENUS", response.data);
  },
  updateMenus({ commit }, payload) {
    commit("SET_MENUS", payload);
  },
  updateModules({ commit }, payload) {
    commit("SET_MODULES", payload);
  },
  alterUrlModule({ commit }, payload) {
    commit("UPDATE_MODULES", {
      slug: payload.slug,
      url: payload.url,
    });
  },
  async setSidebar({ commit }, payload) {
    await commit("SET_SIDEBAR", payload);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
