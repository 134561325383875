import { httpClientOmni } from "@/libraries";
export default {
  async create(payload) {
    return await httpClientOmni.post("/follow-up/create", payload);
  },
  async update(payload) {
    return await httpClientOmni.post("/follow-up/update", payload);
  },
  async delete(hashId) {
    return await httpClientOmni.post("/follow-up/delete", {
      id: hashId,
    });
  },
};
