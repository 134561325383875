<script>
export default {
  name: "BaseTextarea",
  props: {
    theme: {
      type: String,
      default: "primary",
    },
    rows: {
      type: Number,
      default: 10,
    },
    value: {
      type: String,
    },
  },
  computed: {
    computeClass() {
      return `textarea-${this.theme}`;
    },
  },
};
</script>

<template>
  <textarea
    class="form-control"
    :value="value"
    :class="computeClass"
    :rows="rows"
    @input="$emit('input', $event.target.value)"
  ></textarea>
</template>

<style lang="scss" scoped>
.textarea {
  width: 100%;
  color: #6c757d !important;
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  outline: none;
  resize: none;
  &--primary {
    background-color: #f3f3f3 !important;
  }
  &--secondary {
    border: 1px solid #e0e0e0 !important;
  }
}
</style>
