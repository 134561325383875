<template>
  <b-modal
    v-model="modalShow"
    :title="title"
    hide-footer
    modal-class="flip"
    class="v-modal-custom"
    @hide="handleCloseModal"
    @hidden="handleCloseModal"
    size="xl"
    centered
  >
    <form action="javascript:void(0);">
      <b-row>
        <!-- left -->
        <b-col>
          <b-row class="g-3">
            <!-- form start -->
            <b-col xxl="12">
              <div>
                <label for="IntegrationName" class="form-label"
                  >Integration Name<span class="text-danger">*</span></label
                >
                <input
                  v-model="this.form.integration_data.IntegrationName"
                  type="text"
                  class="form-control"
                  id="IntegrationName"
                  placeholder="Integration Name"
                  required
                />
              </div>
            </b-col>
            <b-col xxl="12">
              <div>
                <label for="agentName" class="form-label"
                  >Point Of Contact Name<span class="text-danger"
                    >*</span
                  ></label
                >
                <input
                  v-model="this.form.integration_data.agentName"
                  type="text"
                  class="form-control"
                  id="agentName"
                  placeholder="agent display Name"
                  required
                />
              </div>
            </b-col>
            <b-col xxl="12">
              <div>
                <label for="email" class="form-label"
                  >Contact Email<span class="text-danger">*</span></label
                >
                <input
                  v-model="this.form.integration_data.email"
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Email Office"
                  required
                />
              </div>
            </b-col>
            <b-col xxl="12">
              <div>
                <label for="website" class="form-label"
                  >Website<span class="text-danger">*</span></label
                >
                <input
                  v-model="this.form.integration_data.website"
                  type="url"
                  class="form-control"
                  id="website"
                  placeholder="Your website url (example: https://lenna.ai/)"
                  required
                />
              </div>
            </b-col>
            <b-col xxl="12">
              <div>
                <label for="privacyPolicy" class="form-label"
                  >Privacy Policy<span class="text-danger">*</span></label
                >
                <input
                  v-model="this.form.integration_data.privacyPolicy"
                  type="url"
                  class="form-control"
                  id="privacyPolicy"
                  placeholder="Details about the agent's privacy policy url (example: https://lenna.ai/)"
                  required
                />
              </div>
            </b-col>
            <!-- form end -->
            <!-- form default (dont edit) start -->
            <!-- bot -->
            <div class="col-lg-12 mt-3">
              <div>
                <label class="form-label">Bot</label>
                <v-select
                  label="name"
                  name="bot"
                  class="bg-white"
                  :options="botList"
                  :placeholder="`Select bot`"
                  v-model="this.bot"
                  @option:selected="changeValueBotList"
                />
              </div>
            </div>
            <!-- business unit -->
            <div class="col-lg-12 mt-3">
              <div>
                <label class="form-label">Business Unit</label>
                <v-select
                  label="label"
                  name="branch_office"
                  class="bg-white"
                  :options="businessUnit"
                  :placeholder="`Select bussines unit`"
                  v-model="this.branch_office"
                  @option:selected="changeValueBusinessUnit"
                />
              </div>
            </div>
            <!-- group -->
            <div class="col-lg-12 mt-3">
              <div>
                <label class="form-label">Groups</label>
                <v-select
                  label="label"
                  name="group"
                  class="bg-white"
                  :options="groupManagement"
                  :placeholder="`Select group`"
                  v-model="this.group"
                  @option:selected="changeValueGroupManagement"
                />
              </div>
            </div>
            <!-- form default (dont edit) end -->
          </b-row>
        </b-col>
        <!-- right -->
        <b-col>
          <b-row>
            <!-- form start -->
            <b-col xxl="12">
              <div>
                <label for="GreatingMessage" class="form-label"
                  >Greating Message<span class="text-danger">*</span></label
                >
                <textarea
                  v-model="this.form.integration_data.GreatingMessage"
                  type="text"
                  class="form-control"
                  id="GreatingMessage"
                  placeholder="Greating Message"
                  rows="8"
                  required
                />
              </div>
            </b-col>
            <!-- hanya muncul saat integrasi sudah disimpan -->

            <!-- start testing agent -->
            <div class="col-lg-12 mt-3" v-if="form.integration_data.testUrls">
              <label class="form-label">Test URL </label>
              <span class="text-muted">
                ( testing agent only support on mobile )
              </span>
              <div class="row mt-2">
                <div
                  class="col"
                  v-for="(item, i) in this.form.integration_data.testUrls"
                  :key="i"
                >
                  <b-button
                    type="submit"
                    variant="light"
                    class="w-100"
                    @click.prevent="handleCopy(item.url)"
                  >
                    <template v-if="item.surface == 'SURFACE_ANDROID_WEB'">
                      <i style="font-size: 0.8em" class="ri-android-fill"></i>
                      Android Web
                    </template>
                    <template v-if="item.surface == 'SURFACE_ANDROID_MAPS'">
                      <i
                        style="font-size: 0.8em"
                        class="ri-google-play-fill"
                      ></i>
                      Android Maps
                    </template>
                    <template v-if="item.surface == 'SURFACE_IOS_MAPS'">
                      <i style="font-size: 0.8em" class="ri-app-store-fill"></i>
                      IOS Maps
                    </template>
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end testing agent -->
            <!-- locations start -->
            <b-col xxl="12" v-if="this.form.integration_data.locations">
              <label class="form-label mt-3">
                Locations
                <span class="text-danger">*</span></label
              >
              <a
                href="https://developers-dot-devsite-v2-prod.appspot.com/my-business/content/tools/placeid-lookup"
                target="_blank"
                class="text-primary"
                ><i class="ri-search-line"></i> find PlaceID</a
              >
            </b-col>

            <b-col xxl="12" v-if="this.form.integration_data.locations">
              <transition-group name="list">
                <div v-for="(form, index) in locations" :key="index">
                  <div class="row mt-3 g-0">
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        id="locations"
                        placeholder="Place Id"
                        v-model="form.placeId"
                        :disabled="form.testUrls ? true : false"
                        required
                      />
                    </div>
                    <div class="col-1" v-if="form.testUrls">
                      <button
                        type="button"
                        class="btn"
                        @click.prevent="handleCopy(form.testUrls[0].url)"
                      >
                        <i class="ri-android-fill"></i>
                      </button>
                    </div>
                    <div class="col-1" v-if="form.testUrls">
                      <button
                        type="button"
                        class="btn"
                        @click.prevent="handleCopy(form.testUrls[0].url)"
                      >
                        <i class="ri-google-play-fill"></i>
                      </button>
                    </div>
                    <div class="col-1" v-if="form.testUrls">
                      <button
                        type="button"
                        class="btn"
                        @click.prevent="handleCopy(form.testUrls[1].url)"
                      >
                        <i class="ri-app-store-fill"></i>
                      </button>
                    </div>
                    <div class="col-1" v-if="!form.testUrls">
                      <button
                        type="button"
                        class="btn"
                        @click.prevent="verifyLocation(index)"
                      >
                        <i class="ri-checkbox-circle-fill text-success h4"></i>
                      </button>
                    </div>
                    <div class="col-1">
                      <button
                        type="button"
                        class="btn"
                        @click.prevent="deleteForm(index)"
                      >
                        <i class="ri-delete-bin-6-line text-danger h4"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </transition-group>
            </b-col>
            <b-col xxl="12" v-if="this.form.integration_data.locations">
              <b-button
                variant="soft-success"
                class="px-4 w-100 mt-3"
                @click="addForm"
                ><i class="ri-map-pin-add-fill"> Add Location</i></b-button
              >
            </b-col>
            <!-- locations end -->

            <!-- form end -->
          </b-row>
        </b-col>
      </b-row>
      <!-- button start -->
      <b-row class="mt-3">
        <!-- bottom start-->
        <b-col lg="6">
          <div class="hstack gap-2 justify-content-start">
            <b-button
              type="button"
              class="w-25"
              variant="light"
              @click="handleVerifyAgent(form.id)"
              v-if="form.integration_data.testUrls"
            >
              Verify Agent</b-button
            >
            <b-button
              type="button"
              class="w-25"
              variant="light"
              @click="handleVerifyLocation(form.id)"
              v-if="form.integration_data.testUrls"
            >
              Verify Location</b-button
            >
            <b-button
              type="button"
              class="w-25"
              variant="light"
              @click="handleLaunchAgent(form.id)"
              v-if="form.integration_data.testUrls"
            >
              Launch Agent</b-button
            >
            <b-button
              type="button"
              class="w-25"
              variant="light"
              @click="handleGoLive(form.id)"
              v-if="form.integration_data.testUrls"
            >
              Go live</b-button
            >
          </div>
        </b-col>
        <b-col lg="6">
          <div class="hstack gap-2 justify-content-end">
            <b-button type="button" variant="light" @click="handleCloseModal">
              cancel</b-button
            >
            <b-button
              type="submit"
              variant="success"
              class="px-4"
              @click="handleSubmit"
              >Save</b-button
            >
          </div>
        </b-col>
        <!-- bottom end-->
      </b-row>
      <!-- button end -->
    </form>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash"; // use this if you want to edit data from vuex.
import { httpClientOmni } from "@/libraries";
export default {
  components: {
    vSelect,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    title: {
      type: String,
      default: "Modal Integration",
    },
  },
  data() {
    return {
      modalShow: this.showModal,
      form: {
        integration_data: {},
      },
      locations: [],
      branch_office: null,
      group: null,
      bot: null,
    };
  },
  computed: {
    // dont forget this only getting data from vuex, ==================================== ||
    // make sure you call action to update this data before its show in modal box ======= ||
    ...mapGetters({
      businessUnit: "ticket/getBranchOffice",
      groupManagement: "ticket/getGroupManagement",
      botList: "bot/botList",
    }),
  },
  watch: {
    // ================================================================================== ||
    // SHOW MODAL FUNC ================================================================== ||
    // first func running aftar modalbox trigged ======================================== ||
    // Set the value of modalShow to newVal ============================================= ||
    // Deep clone the data object and assign it to form ================================= ||
    // Set branch_office, group, and bot properties based on form data ================== ||
    // Generate the webhook URL using omniBaseUrl and form.id =========================== ||
    showModal(newVal) {
      this.modalShow = newVal;
      console.log("data form dari parent ", this.data);
      // for edit
      if (this.data != null) {
        this.form = cloneDeep(this.data);
        this.branch_office = this.form.business_unit?.branch_office || null;
        this.group = this.form.group?.name || null;
        this.bot = this.form.bot?.name || null;
        this.locations = this.form.integration_data?.locations || null;
      } else {
        this.form = { integration_data: {} };
        this.branch_office = null;
        this.group = null;
        this.bot = null;
        this.locations = [];
      }
    },
  },
  methods: {
    // ================================================================================== ||
    // handleCloseModal FUNC ============================================================ ||
    handleCloseModal() {
      this.$emit("onClose"); // Emit an event named "onClose" to notify parent components
      this.modalShow = false; // Set the value of modalShow to false, hiding the modal
    },
    // ================================================================================== ||
    // handleSubmit FUNC ================================================================ ||
    // The && operator short-circuits if the left operand is falsy, ===================== ||
    // so the $emit call is only executed if the validate method returns a truthy value = ||
    handleSubmit() {
      this.form.integration_data.locations = this.locations ?? {};
      this.validate() && this.$emit("onSubmit", this.form);
    },

    // ================================================================================== ||
    // validate FUNC ==================================================================== ||
    validate() {
      // this is shorthand ============================================================== ||
      // If integration_data is undefined or null, set it to an empty object ============ ||
      const data = this.form.integration_data || {};
      // If all three fields are truthy, the expression inside the !! operator ========== ||
      // evaluates to true, and !! converts it to the boolean value true. =============== ||
      // If any of the fields are falsy, the expression evaluates to false, ============= ||
      // and !! converts it to the boolean value false. ================================= ||
      return !!(
        data.IntegrationName &&
        data.agentName &&
        data.email &&
        data.privacyPolicy &&
        data.website &&
        data.locations
      );
      // this is same func as above and working too ===================================== ||
      // use this if other func not working ============================================= ||
      // if (!this.form.integration_data.IntegrationName) {
      //   return;
      // }
      // if (!this.form.integration_data.email) {
      //   return;
      // }
      // if (!this.form.integration_data.website) {
      //   return;
      // }
      // return true;
    },
    // ================================================================================== ||
    // changeValueBotList FUNC ========================================================== ||
    changeValueBotList(val) {
      console.log("bot:", val);
      this.form.bot_id = val.id;
      this.bot = val.name;
    },
    // ================================================================================== ||
    // changeValueGroupManagement FUNC ================================================== ||
    changeValueGroupManagement(val) {
      console.log("group:", val);
      this.form.group_id = val.value;
      this.group = val.label;
    },
    // ================================================================================== ||
    // changeValueBusinessUnit FUNC ===================================================== ||
    changeValueBusinessUnit(val) {
      console.log("business_unit:", val);
      this.form.business_unit_id = val.id;
      this.branch_office = val.label;
    },
    // ================================================================================== ||
    // handleCopy FUNC ================================================================== ||
    // there 2 way to execute this func you can call the older one or latest one ======== ||
    // by default its Use modern clipboard API to copy the value to clipboard =========== ||
    handleCopy(url) {
      // Use modern clipboard API to copy the value to clipboard
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.successToast("URL copied successfully.");
        })
        .catch(() => {
          this.errorToast("Failed to copy URL.");
        });
    },
    async handleVerifyAgent(id) {
      console.log("handle Verify Agent Button clicked");
      let response = await httpClientOmni.post(
        "/google-business/agent/requestVerification",
        {
          id: id,
        }
      );
      console.log(response);
      return response;
    },
    async handleVerifyLocation(id) {
      console.log("handle Verify Location Button Clicked");
      let response = await httpClientOmni.post(
        "/google-business/location/requestVerification",
        {
          id: id,
        }
      );
      console.log(response);
      return response;
    },
    async handleLaunchAgent(id) {
      console.log("handle Launch Agent Button clicked");
      let response = await httpClientOmni.post(
        "/google-business/agent/requestLaunch",
        {
          id: id,
        }
      );
      console.log(response);
      return response;
    },
    async handleGoLive(id) {
      console.log("handle GoLive Button Clicked");
      let response = await httpClientOmni.post(
        "/google-business/location/requestLaunch",
        {
          id: id,
        }
      );
      console.log(response);
      return response;
    },
    addForm() {
      this.locations.push({ placeId: "" });
      console.log("form add : ", this.form);
    },
    async deleteForm(index) {
      await this.deleteLocation(index);
      this.locations.splice(index, 1);
    },
    async verifyLocation(index) {
      console.log("verify clicked w index ", index);
      let response = await httpClientOmni.post(
        "/google-business/location/addLocation",
        {
          ...this.form,
          location: this.locations[index],
        }
      );
      console.log(response);
      if (response.data.error) {
        this.errorToast(response.data.error);
        return;
      }
      this.locations[index] = response.data;
      this.successToast("add new location successfully");
      return;
    },
    async deleteLocation(index) {
      console.log("delete Location clicked w index ", index);
      let response = await httpClientOmni.post(
        "/google-business/location/deleteLocation",
        {
          ...this.form,
          location: this.locations[index],
        }
      );
      console.log(response);
      if (response.data.error) {
        this.errorToast(response.data.error);
        return;
      }
      this.successToast("Delete location successfully");
      return;
    },
  },
};
</script>
