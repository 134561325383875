<template>
  <!-- <fragment> -->
  <base-label :text="label" /> <br />
  <base-input
    type="text"
    :placeholder="placeholder"
    :value="value"
    @input="onInput"
  />
  <!-- </fragment> -->
</template>
<script>
// import { Fragment } from "vue-fragment";
export default {
  components: {
    // Fragment,
  },
  name: "UiDynamicForm",
  data() {
    return {
      input: "",
    };
  },
  props: {
    placeholder: { required: true },
    value: { required: true },
    label: { required: true },
  },
  methods: {
    onInput(e) {
      this.input = e;
      this.$emit("input", this.input);
    },
  },
};
</script>
