import { httpClientOmni } from "@/libraries";

export default {
  async getFeedList(params) {
    return await httpClientOmni.get("/comment/feeds", {
      params,
    });
  },
  async getFeed(id) {
    return await httpClientOmni.get(`/comment/feeds/${id}`);
  },
  async getMoreComments(params) {
    const response = await httpClientOmni.get("/comment/get-more-comment", {
      params,
    });
    return response;
  },
  async getMoreCommentsTicket(payload) {
    const response = await httpClientOmni.post(
      "/comment/get-more-comment-ticket",
      payload
    );
    return response;
  },
  async sendComment(payload) {
    const response = await httpClientOmni.post("comment/send-comment", payload);
    return response;
  },
};
