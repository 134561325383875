import { httpClientOmni } from "@/libraries";

export default {
  async exportExcel(payload) {
    const response = await httpClientOmni.get(
      `excel/report-time-served`,
      {
        params: payload,
      },
      {
        responseType: "blob",
      }
    );
    return response;
  },
  async exportPdf(payload) {
    const response = await httpClientOmni.get(`report-served/export`, {
      params: payload,
    });
    return response;
  },
};
