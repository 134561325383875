import { httpClientOmni } from "@/libraries";
import * as types from "./mutation_type";
// // import notification from "@/helpers/EventBus";

export const requestSegment = ({ commit }) => commit(types.REQUEST_SEGMENT);

export const receiveSegmentSuccess = ({ commit }, payload) =>
  commit(types.RECEIVE_FETCH_SEGMENT_SUCCESS, payload);

export const receiveSegmentError = ({ commit }) =>
  commit(types.RECEIVE_FETCH_SEGMENT_ERROR);

export const receiveCreateSegmentSuccess = ({ commit }, payload) =>
  commit(types.RECEIVE_CREATE_SEGMENT_SUCCESS, payload);
export const receiveCreateSegmentError = ({ commit }) =>
  commit(types.RECEIVE_CREATE_SEGMENT_ERROR);

export const receiveDeleteSegmentSuccess = ({ commit }, value) =>
  commit(types.RECEIVE_DELETE_SEGMENT_SUCCESS, value);
export const receiveDeleteSegmentError = ({ commit }) =>
  commit(types.RECEIVE_DELETE_SEGMENT_ERROR);

export const receiveUpdateSegmentSuccess = ({ commit }, value) =>
  commit(types.RECEIVE_UPDATE_SEGMENT_SUCCESS, value);
export const receiveUpdateSegmentError = ({ commit }) =>
  commit(types.RECEIVE_UPDATE_SEGMENT_ERROR);

export const fetchSegment = ({ dispatch }) => {
  dispatch("requestSegment");
  return httpClientOmni
    .get("/segment")
    .then((response) => {
      this.emitter.emit("segmentFetched");
      dispatch("receiveSegmentSuccess", response.data.data);
    })
    .catch((_) => {
      console.log(_);
      dispatch("receiveSegmentError");
    });
};

export const createSegment = ({ dispatch }, payload) => {
  dispatch("requestSegment");
  return httpClientOmni
    .post("/segment", { ...payload, query: JSON.stringify(payload.query) })
    .then((response) => {
      this.emitter.emit("segmentCreated");
      dispatch("receiveCreateSegmentSuccess", response.data.data);
    })
    .catch((_) => {
      console.log(_);
      dispatch("receiveCreateSegmentError");
    });
};

export const updateSegment = ({ dispatch }, payload) => {
  dispatch("requestSegment");
  return httpClientOmni
    .post("/segment/update", {
      ...payload,
      query: JSON.stringify(payload.query),
    })
    .then((_) => {
      console.log(_);
      dispatch("receiveUpdateSegmentSuccess", payload);
      this.emitter.emit("segmentUpdated");
    })
    .catch((_) => {
      console.log(_);
      dispatch("receiveUpdateSegmentError");
    });
};

export const deleteSegment = ({ dispatch }, segment_id) => {
  dispatch("requestSegment");
  return httpClientOmni
    .post("/segment/delete", { id: segment_id })
    .then((_) => {
      console.log(_);
      this.emitter.emit("segmentDeleted");
      dispatch("receiveDeleteSegmentSuccess", segment_id);
    })
    .catch((_) => {
      console.log(_);
      dispatch("receiveDeleteSegmentError");
    });
};
