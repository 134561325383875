import { httpClientBot } from "@/libraries";
const state = {
  stories: [],
};

const getters = {
  getStories(state) {
    return state.stories;
  },
};

const mutations = {
  GET_STORIES: (state, payload) => {
    state.stories = payload;
  },
};

const actions = {
  async getStories(context) {
    console.log("Get Stories");
    let response = await httpClientBot.get(`/stories`);
    context.commit("GET_STORIES", response.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
