import { reject, findIndex, find } from "lodash";
import { httpClientOmni } from "@/libraries";

const state = {
  notif: [],
  notifList: [],
};
const getters = {
  notifList(state) {
    return state.notifList;
  },
};
const mutations = {
  SET_NOTIF_LIST: (state, payload) => {
    state.notifList = payload;
  },
  SELECT_NOTIF: (state, payload) => {
    const index = state.notifList.findIndex((item) => item.id == payload.id);
    state.notifList.splice(index, 1);
  },
  UPDATE_NOTIF_LIST: (state, payload) => {
    let isExist = find(state.notifList, function (obj) {
      return obj.id == payload.id;
    });
    if (isExist) {
      // is notif exist
      let notifIndex = findIndex(state.notifList, function (obj) {
        return obj.id == payload.id;
      });

      let prevUnreadCount = state.notifList[notifIndex].unread_count;
      state.notifList[notifIndex].items.push(payload.items[0]);

      state.notifList[notifIndex].unread_count = prevUnreadCount + 1;

      return;
    } else {
      // if doesnt exist
      state.notifList.push(payload);
    }
  },
  READ_NOTIF: (state, payload) => {
    let notifications = state.notifList;
    let unreadNotif = reject(notifications, function (o) {
      return o.data.room.id == payload.id;
    });
    state.notifList = unreadNotif;
  },
  NEW_NOTIF: (state, payload) => {
    state.notif = [...state.notif, payload];
  },
};
const actions = {
  async getNotifList(context, rootGetters) {
    let user_id = rootGetters.localStorage.decodedUserId;
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/app/get-notif`,
      {
        user_id: user_id,
      }
    );
    console.log("notif", response);
    context.commit("SET_NOTIF_LIST", response);
  },
  async getNotif(context, rootGetters) {
    let user_id = rootGetters.localStorage.decodedUserId;
    console.log("payload", user_id);
    try {
      let response = await httpClientOmni.get(`notification/get-notif`, {
        user_id: user_id,
      });
      console.log("response get", response);
      context.commit("SET_NOTIF_LIST", response);
    } catch (err) {
      // commit(Types.ERROR_SERVICE, err);
      console.log("error response", err);
    }
  },
  updateNotifList(context, payload) {
    context.commit("UPDATE_NOTIF_LIST", payload);
  },
  selectNotif(context, payload) {
    context.commit("SELECT_NOTIF", payload);
  },
  readNotif(context, payload) {
    context.commit("READ_NOTIF", payload);
  },
  newNotif(context, payload) {
    context.commit("NEW_NOTIF", payload);
  },
};
export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
