import url from "../../environment/url";

export default {
  baseURL: url.authUrl,
  timeout: 90000,
  headers: {
    userId: JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user")).id
      : "",
    token: JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user")).token.access_token
      : "",
    Authorization:
      "Bearer " +
      (JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user")).token.access_token
        : ""),
  },
};
