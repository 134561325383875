<template>
  <b-button
    class="btn d-flex gap-1"
    :disabled="loading || disabled"
    :class="[buttonClass, sizeclass]"
    :variant="variant"
    @click="handleClick"
  >
    <i :class="`ri-${icon}`" v-if="!loading"></i>
    <span v-if="loading">
      <b-icon icon="circle-fill" animation="throb"></b-icon>
      Loading ..
    </span>
    <slot v-else> </slot>
  </b-button>
</template>
<script>
export default {
  name: "ButtonIcon",
  props: {
    icon: {
      type: String,
      default: "plus",
    },
    theme: {
      type: String,
      default: "default",
    },
    size: {
      type: String,
      default: "md",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClass() {
      return `btn-${this.theme}`;
    },
    sizeclass() {
      return `btn-${this.size}`;
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>
<style lang="scss" scoped>
// refer to scss/component/button.scss
</style>
