import state from "./state";
import mutations from "./mutation";
import * as actions from "./action";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
