<template>
  <div class="col pt-4 px-3 bg-greenish text-center">
    <img style="margin-top: 100px;" src="@/assets/images/ui_elements/coming-soon.png" /><br>
    <span class="mt-2" style="color: #828282;font-size: 24px">Coming Soon!</span><br>
    <span class="mt-2" style="color: #828282;font-size: 14px;">Something great is on the way to your screen</span><br>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
span {
  font-family: "hk-regular" !important;
}
</style>
