const state = {
  areaList: [],
};

const getters = {
  areaList(state) {
    return state.areaList;
  },
};
const mutations = {};
const actions = {};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
