class Transformer {
	response (response) {
		return {
			data: response.data.data,
			error: response.data.error,
			message: response.data.message,
			statusCode: response.data.statuscode,
			success: response.data.success,
			// errors: response.data ? response.data.errors : null
		};
	}
	toJson(data) {
		return JSON.parse(data);
	}
}

export default new Transformer();
