import { httpClientBot } from "@/libraries";

export default {
  async createFunctionList(payload) {
    return await httpClientBot.post("/function-lists", payload);
  },
  async updateFunctionList(payload) {
    return await httpClientBot.put(
      "/function-lists/" + payload.hashed_id,
      payload
    );
  },
  async deleteFunctionList(hashedId) {
    return await httpClientBot.delete(
      `/function-lists?functionListCheckBox=["${hashedId}"]`
    );
  },
  async massDeleteFunctionList(payload) {
    return await httpClientBot.delete(
      `/function-lists?functionListCheckBox=["${payload.functionListCheckBox}"]`
    );
  },
  async getTrashBinList() {
    return await httpClientBot.get("/function-lists/trash");
  },
  async restoreTrashBin(id) {
    return await httpClientBot.post(`/function-lists/${id}/restore`);
  },
  async getFunctionList() {
    return await httpClientBot.get(`/function-lists`);
  },
};
