<template>
  <Layout>
    <PageHeader :title="getTitle" :items="items" />
    <div class="container-fluid">Contact Admin</div>
  </Layout>
</template>
<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      items: [
        {
          text: "Settings",
          href: "/",
        },
        {
          text: "Setting",
          active: true,
        },
      ],
    };
  },
  computed: {
    getTitle() {
      return this.$route.meta.title;
    },
  },
};
</script>
<style scoped>
.badge img {
  width: 24px;
}

.channel-list .channel,
.flex-setting {
  padding: 10px;
  border-radius: 4px;
}

.channel-list .channel:hover,
.flex-setting:hover {
  background: var(--vz-boxed-body-bg);
}

.channel-list {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
}

.setting-list {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 250px);
}
</style>
