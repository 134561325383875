import { httpClientBot } from "@/libraries";

export default {
  async getBotConfigurationProfile() {
    return await httpClientBot.get("/settings/bot-configuration/profile");
  },
  async removeAvatar(image) {
    return await httpClientBot.post(
      "/settings/bot-configuration/remove-avatar",
      {
        fileToDelete: image,
      }
    );
  },
  async saveAvatar(image) {
    return await httpClientBot.post(
      "/settings/bot-configuration/profilesaveimage",
      {
        avatar: {
          current_avatar: image.current_avatar,
          uploaded_avatar: image.uploaded_avatar,
        },
      }
    );
  },
  async updateBotConfig(payload) {
    return await httpClientBot.post(
      "/settings/bot-configuration/profile-update",
      payload
    );
  },
  async changeStatus(payload) {
    return await httpClientBot.post(
      "/settings/bot-configuration/change-status",
      payload
    );
  },
};
