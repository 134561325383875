<template>
  <input
    :placeholder="placeholder"
    :type="type"
    :value="modelValue"
    class="form-control"
    style="padding: 5px 14px"
    :class="computeClass"
    @keyup.enter="$emit('keyupEnter')"
    @input="updateValue"
    @change="(e) => $emit('change', e.target.value)"
  />
</template>
<script>
export default {
  name: "BaseInput",
  props: {
    theme: {
      type: String,
      default: "primary",
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
  },
  computed: {
    computeClass() {
      return `lenna-input--${this.theme}`;
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
// style refer on src/styles/scss/component/form.scss
</style>
