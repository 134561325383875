<template>
  <b-modal
    hide-header
    hide-footer
    class="border-0"
    v-model="isOpenModal"
    centered
    size="half"
    @hidden="hideModal"
    body-class="p-0 m-0 border-0"
    content-class="border-0"
    id="modal-scoped"
  >
    <div class="container p-5 text-center">
      <lottie
        class="avatar-xl"
        colors="primary:#405189,secondary:#f06548"
        :options="defaultOptions1"
        :height="90"
        :width="90"
      />
      <div class="mt-4 text-center">
        <h4></h4>
        <p class="text-muted fs-14 mb-4">
          {{ isInformation }}
        </p>
        <div class="hstack gap-2 justify-content-center remove">
          <button
            class="btn btn-link link-success fw-medium text-decoration-none"
            data-bs-dismiss="modal"
            @click="hideModal"
          >
            <i class="ri-close-line me-1 align-middle"></i> Close
          </button>
          <base-button
            :loading="isLoading"
            class="btn btn-danger"
            id="delete-record"
            @click="handleSubmit"
          >
            {{ submitText }}
          </base-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import animationData1 from "@/components/widgets/gsqxdxog.json";
import Lottie from "@/components/widgets/lottie.vue";
import { debounce } from "lodash";
export default {
  name: "ModalDelete",
  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      default: "Are You Sure Want to Delete ?",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isInformation: {
      type: String,
      default:
        "Deleting your order will remove all of your information from our database.",
    },
    submitText: {
      type: String,
      default: "Yes, Delete It",
    },
  },
  components: {
    Lottie,
  },
  data() {
    return {
      isOpen: this.showModal,
      isOpenModal: this.showModal,
      defaultOptions1: { animationData: animationData1 },
    };
  },
  methods: {
    onShowModal() {
      this.isOpenModal = true;
    },
    onHideModal() {
      this.isOpenModal = false;
      this.$emit("onClose");
    },
    handleSubmit() {
      this.onSubmit(this);
    },
    onSubmit: debounce((vm) => {
      vm.$emit("onSubmit");
    }, 500),

    hideModal() {
      this.$emit("onHidden");
    },
  },
  watch:{
    showModal(newVal) {
      if (newVal) {
        this.onShowModal();
      } else {
        this.onHideModal();
      }
    },
  },
};
</script>
