import DeviceService from "@/services/parking/device_service";
const state = {
  deviceList: [],
};

const getters = {
  device(state) {
    return state.deviceList;
  },
};
const mutations = {
  SET_DEVICE: (state, payload) => {
    state.deviceList = payload;
  },
};
const actions = {
  async getDevice({ commit }, payload) {
    try {
      console.log("payload", payload);
      const { data: response } = await DeviceService.getDevice(payload);
      commit("SET_DEVICE", response.data);
    } catch (err) {}
  },
  async createDevice({ commit }, payload) {
    console.log("create", payload);
    try {
      const { data: response } = await DeviceService.createDevice(payload);
    } catch (err) {}
  },
  async updateDevice({ commit }, payload) {
    try {
      const { data: response } = await DeviceService.updateDevice(payload);
    } catch (err) {}
  },
  async deleteDevice({ commit }, payload) {
    try {
      const { data: response } = await DeviceService.deleteDevice(payload);
    } catch (err) {}
  },
  async syncDevice({ dispatch }, payload) {
    try {
      const { data: response } = await DeviceService.syncDevice();
      dispatch("getDevice");
      return response;
    } catch (err) {}
  },
  async commandDevice({ dispatch }, payload) {
    try {
      const { data: response } = await DeviceService.commandDevice(payload);
    } catch (err) {}
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
