import { StoryGroupsService } from "@/services";
var _ = require("lodash");

const Types = {
  SET_STORY_GROUPS: "SET_STORY_GROUPS",
  ADD_STORY_GROUPS: "ADD_STORY_GROUPS",
  DELETE_STORY_GROUPS: "DELETE_STORY_GROUPS",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_LOADING: "SET_LOADING",
  SET_TRASH_BIN_LIST: "SET_TRASH_BIN_LIST",
};

const state = {
  data: [],
  error: null,
  loading: false,
  trashBinList: [],
  stories: [],
};

const getters = {
  get(state) {
    return state.data;
  },
  trashBinList(state) {
    return state.trashBinList;
  },
};

const mutations = {
  [Types.SET_STORY_GROUPS]: (state, payload) => (state.data = payload),
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.ERROR_SERVICE]: (state, payload) => (state.error = payload),
  [Types.DELETE_STORY_GROUPS](state, payload) {
    let storyGroups = _.clone(state.data);
    _.remove(storyGroups, function (o) {
      return o.hashed_id == payload;
    });
    state.data = storyGroups;
  },
  [Types.ADD_STORY_GROUPS](state, payload) {
    let storyGroups = _.clone(state.data);
    state.data = _.concat(payload, storyGroups);
  },
  [Types.SET_TRASH_BIN_LIST]: (state, payload) =>
    (state.trashBinList = payload),
  SET_STORIES: (state, payload) => {
    state.stories = payload;
  },
};

const actions = {
  async get({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await StoryGroupsService.getStoryGroupsCard();
      commit(Types.SET_STORY_GROUPS, response.data);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async delete({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await StoryGroupsService.deleteStoryGroups(payload);
      if (response.data.success) {
        commit(Types.DELETE_STORY_GROUPS, payload);
      }
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async create({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await StoryGroupsService.createStoryGroup(payload);
      if (response.data.success) {
        commit(Types.ADD_STORY_GROUPS, response.data.data);
      }
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getTrashBin({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await StoryGroupsService.getTrashBinList();
      commit(Types.SET_TRASH_BIN_LIST, response.data);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async restoreTrashBin({ commit }, id) {
    commit(Types.SET_LOADING, true);
    try {
      await StoryGroupsService.restoreTrashBin(id);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async update({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      await StoryGroupsService.updateStoryGroup(payload);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async detail({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data } = await StoryGroupsService.detailStoryGroup(payload);
      commit("SET_STORIES", data.data.stories);
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
