import { httpClientBot } from "@/libraries";

const state = {
  reading_library: [],
};

const getters = {
  getDetail(state) {
    return state.reading_library;
  },
};

const mutations = {
  GET_DETAIL: (state, payload) => {
    state.reading_library = payload;
  },
};

const actions = {
  async getDetail(context, id) {
    let response = await httpClientBot.get(`/v3/machine-reading/${id}/detail`);

    context.commit("GET_DETAIL", response.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
