import isString from "lodash/isString";
import { useToast } from "vue-toastification";
const toast = useToast();
const toastOption = {
  position: "top-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};
class Toast {
  success(message) {
    toast.success(message, toastOption);
  }
  error(message) {
    let err = "Failed";
    if (isString(message)) {
      err = message;
    }
    toast.error(err, toastOption);
  }
}
export default new Toast();
