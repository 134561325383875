<template>
  <b-modal
    hide-header
    hide-footer
    class="border-0"
    v-model="isOpen"
    centered
    :size="size"
    @hidden="hideModal"
    body-class="p-0 m-0 border-0"
    content-class="border-0"
    id="modal-scoped"
  >
    <slot name="modal-header">
      <div class="p-3 bg-primary d-flex justify-content-between rounded-top">
        <h6 class="modal-title text-light" id="exampleModalLabel">
          {{ title }}
        </h6>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          id="close-modal"
        ></button>
      </div>
    </slot>
    <div class="container py-3 px-4">
      <div class="row">
        <form class="form-material" @submit.prevent="submitForm">
          <div class="flex-grow-1">
            <div class="mb-3" v-for="(row, index) in types" :key="index">
              <h4 class="fs-16 lh-base mb-0">
                {{ row.label }}
              </h4>
              <p class="mb-0 text-muted">
                {{ row.description }}
              </p>
              <template v-if="row.key == 'select'">
                <v-select
                  class="fs-14 mt-2"
                  label="label"
                  v-model="row.value"
                  :reduce="(option) => option.id"
                  :options="row.options"
                  :placeholder="`Select ${row.label}`"
                ></v-select>
              </template>
            </div>
            <div class="d-flex gap-3 mt-4">
              <button
                type="button"
                class="d-flex btn btn-success"
                @click="reset"
              >
                <i class="ri-refresh-line me-2"></i>
                Reset
              </button>
              <button type="submit" class="d-flex btn btn-primary">
                <i class="ri-filter-fill me-2"></i>
                Filter Now
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { debounce } from "lodash";
import vSelect from "vue-select";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    form: {
      type: Object,
    },
    title: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    types: {
      type: Object,
      // required: true,
    },
    size: {
      type: String,
      default: "xs",
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {
      // debounce,
      isOpen: this.showModal,
    };
  },
  computed: {},
  watch: {
    showModal(newVal) {
      console.log("show filters in modal");
      if (newVal) {
        this.onShowModal();
      } else {
        this.onHideModal();
      }
    },
  },
  methods: {
    submitForm() {
      this.onSubmit(this);
    },

    onSubmit: debounce((vm) => {
      vm.$emit("onSubmit", vm.types);
    }, 500),

    reset() {
      for (let i = 0; i < this.types.length; i++) {
        // eslint-disable-next-line vue/no-mutating-props
        this.types[i].value = null;
      }
      console.log("Types ", this.types);
    },
    onShowModal() {
      this.isOpen = true;
    },
    onHideModal() {
      this.isOpen = false;
      this.$emit("onClose");
    },
    close() {
      this.$emit("close");
      this.entity = {
        entity_name: "",
        parse_method: "",
        entityValues: [],
      };
    },
    hideModal() {
      this.$emit("onHidden");
    },
    handleSubmit() {
      this.onSave(this);
    },

    onSave: debounce((vm) => {
      vm.$emit("onSave", vm.entity);
      vm.entity = {
        entity_name: null,
        parse_method: null,
        entityValues: [
          // {
          //   values: "",
          //   entity_type: "",
          //   similar_words: [],
          // },
        ],
      };
    }, 500),
  },
  unMounted() {
    this.handleCloseModal();
  },
};
</script>
<style>
.fs-9 {
  font-size: 9pt;
}
</style>
