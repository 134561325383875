import { WaBroadcastService } from '@/services';

const Types = {
    SET_LOADING: 'SET_LOADING',
    SET_ERROR: 'SET_ERROR',
    SET_FORM: 'SET_FORM'
};

const state = {
    data: [],
    error: null,
    loading: false,
    form: {
        templateName: '',
        template: {},
        scheduleDate: null,
        scheduleTime: null,
        targetRecipient: '',
        recipients: [],
        inputParams: [],
        description: '',
        integrationId: '',
        sentVia: 'platform',
        broadcastName: '',
        headerUrl: '',
        scheduleAt: '',
        channelId: 4,
        isDynamicCSVParam: false,
        dynamicCSVParam: [],
        sendNow: false,
        header: null,
        footer: null,
        button: null,
        dynamicUrlParam: '',
        dynamicHeaderText: '',
        groupId: null,
        sent_by: '',
        batch: []
    }
};

const getters = {
    loading: (state) => state.loading,
    error: (state) => state.error,
    form: (state) => state.form
};

const mutations = {
    [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
    [Types.SET_ERROR]: (state, payload) => (state.error = payload),
    [Types.SET_FORM]: (state, payload) => (state.form = payload)
};

const actions = {
    async createBroadcast({ commit }, payload) {
        commit(Types.SET_LOADING, true);
        try {
            const response = await WaBroadcastService.createBroadcast(payload);
            return response;
        } catch (error) {
            commit(Types.SET_ERROR, error);
        }
        commit(Types.SET_LOADING, false);
    },
    async deleteBroadcast({ commit }, payload) {
        commit(Types.SET_LOADING, true);
        try {
            const response = await WaBroadcastService.deleteBroadcast(payload);
            return response;
        } catch (error) {
            commit(Types.SET_ERROR, error);
        }
        commit(Types.SET_LOADING, false);
    },
    async editBroadcast({ commit }, payload) {
        commit(Types.SET_LOADING, true);
        try {
            const response = await WaBroadcastService.editBroadcast(payload);
            return response;
        } catch (error) {
            commit(Types.SET_ERROR, error);
        }
        commit(Types.SET_LOADING, false);
    },
    async exportCsv({ commit }, payload) {
        commit(Types.SET_LOADING, true);
        try {
            const response = await WaBroadcastService.exportCsv(payload);
            return response;
        } catch (error) {
            console.log('error export csv', error);
            commit(Types.SET_ERROR, error);
        }
        commit(Types.SET_LOADING, false);
    },
    async exportPdf({ commit }, payload) {
        commit(Types.SET_LOADING, true);
        try {
            const response = await WaBroadcastService.exportPdf(payload);
            return response;
        } catch (error) {
            console.log('error export pdf', error);
            commit(Types.SET_ERROR, error);
        }
        commit(Types.SET_LOADING, false);
    },
    watchForm({ commit }, payload) {
        commit(Types.SET_FORM, payload);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
