<template>
  <div class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{ new Date().getFullYear() }} &copy; Lenna.</div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">Powered by Lenna</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseFooter",
};
</script>
