import axios from "axios";

export default {
  async login(scope) {
    // login with facebook then authenticate with the API to get a JWT auth token
    const { authResponse } = await new Promise((resolve) => {
      FB.login(
        function (response) {
          if (response.authResponse) {
            // returns a User Access Token with scopes requested
            const accessToken = response.authResponse.accessToken;

            const data = {
              authResponse: {
                accessToken: response.authResponse.accessToken,
                message: {
                  success: true,
                  access_token: accessToken,
                },
              },
            };

            resolve(data);
            // store access token for later
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          // scope:
          //   "pages_manage_metadata,instagram_basic,instagram_manage_messages,pages_read_engagement,pages_show_list,pages_messaging",
          scope: scope,
        }
      );
    });
    console.log("suth response", authResponse);
    if (!authResponse) return;
    return authResponse;
  },

  // async apiAuthenticate(accessToken) {
  //   // authenticate with the api using a facebook access token,
  //   // on success the api returns an account object with a JWT auth token
  //   const response = await axios.post(`${baseUrl}/authenticate`, {
  //     accessToken,
  //   });
  //   const account = response.data;
  //   return account;
  // },

  logout() {
    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    FB.api("/me/permissions", "delete", null, () => FB.logout());
  },
  authenticate(accessToken) {
    axios
      .get(`https://graph.facebook.com/v13.0/me?access_token=${accessToken}`)
      .then((response) => {
        const { data } = response;
        if (data.error) return alert(data.error.message);

        console.log("response", data);

        // let account = accounts.find((x) => x.facebookId === data.id);
        // if (!account) {
        //   // create new account if first time logging in
        //   account = {
        //     id: newAccountId(),
        //     facebookId: data.id,
        //     name: data.name,
        //     extraInfo: `This is some extra info about ${data.name} that is saved in the API`,
        //   };
        //   accounts.push(account);
        //   localStorage.setItem(accountsKey, JSON.stringify(accounts));
        // }

        // return ok({
        //   ...account,
        //   token: generateJwtToken(account),
        // });
      });
  },
};
