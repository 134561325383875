export default {
  path: "/:appId/dashboard",
  name: "dashboard",
  props: true,
  component: () => import("@/views/dashboard/pages/DashboardNew.vue"),
  // redirect: {
  //   name: "dashboard.main",
  // },
  meta: {
    title: "Dashboard",
  },
  // children: [
  //   {
  //     path: "main",
  //     name: "dashboard.main",
  //     component: () => import("@/views/dashboard/pages/DashboardNew.vue"),
  //   },
  // ],
};
