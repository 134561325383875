<template>
  <b-modal
    hide-footer
    hide-header
    class="border-0"
    modal-class="lenna-modal"
    :size="size"
    centered
    v-model="open"
    :id="id"
    v-bind="$attrs"
    :no-close-on-backdrop="preventClosing"
    @hide="handleCloseModal"
    @hidden="handleCloseModal"
  >
    <slot name="modal-header">
      <div
        class="ps-4 pe-3 py-2 bg-primary d-flex justify-content-between rounded-top"
      >
        <h5 class="modal-title text-light my-auto">{{ modalTitle }}</h5>
        <i
          class="ri-close-line text-white fs-4 pointer"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="handleCloseModal"
          v-if="!hideCloseIcon"
        ></i>
      </div>
    </slot>
    <div :class="computeClass">
      <slot></slot>
    </div>
    <!-- <template #modal-footer>
      <base-button :variant="'primary'" @click="handleSubmit">
        {{ titleSubmit }}
      </base-button>
    </template> -->
    <slot name="modal-footer" v-if="modalFooter">
      <div class="px-4 pb-4 hstack gap-2 justify-content-end">
        <base-button variant="light" class="me-2" @click="handleCancel">
          {{ titleCancel }}
        </base-button>
        <base-button variant="primary" :loading="loading" @click="handleSubmit">
          {{ titleSubmit }}
        </base-button>
      </div>
    </slot>
  </b-modal>
</template>
<script>
import { debounce, clone } from "lodash";
export default {
  name: "BaseModal",
  // components: {
  //   Fragment,
  // },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: true,
    },
    modalTitle: {
      type: String,
      default: "",
    },
    modalFooter: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "md", // xl, lg, sm
    },
    noPadding: {
      type: Boolean,
      default: true,
    },
    hideCloseIcon: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "lenna-modal",
    },
    titleSubmit: {
      type: String,
      default: "Submit",
    },
    titleCancel: {
      type: String,
      default: "Cancel",
    },
    useCancel: {
      type: Boolean,
      default: false,
    },
    forceLoading: {
      type: Boolean,
      default: false,
    },
    preventClosing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debounce,
      open: false,
      loading: false,
    };
  },
  computed: {
    computeClass() {
      if (this.noPadding) {
        return "";
      } else {
        return "p-4";
      }
    },
    computeClassIcon() {
      if (this.noPadding) {
        return "close__icon--nopadding";
      }
      return "";
    },
  },
  watch: {
    isOpen(newVal) {
      this.open = newVal;
    },
    forceLoading: {
      handler(newVal) {
        this.loading = clone(newVal);
        // console.log("this.loading", this.loading);
      },
      immediate: true,
    },
  },
  methods: {
    handleCloseModal() {
      this.loading = false;
      this.onClose(this);
    },
    handleSubmit() {
      // this.loading = true;
      this.onSubmit(this);
    },
    handleCancel() {
      this.onCancel(this);
      this.onClose(this);
    },
    onSubmit: debounce(function (vm) {
      vm.$emit("onSubmit");
      // if (vm.forceLoading == false) {
      //   setTimeout(() => {
      //     vm.loading = false;
      //     // console.log("onSubmit loading", vm.loading);
      //   }, 100);
      // }
    }, 250),
    onClose: debounce(function (vm) {
      vm.$emit("onClose");
    }, 250),
    onCancel: debounce(function (vm) {
      vm.$emit("onCancel");
    }, 250),
  },
  // inheritAttrs: false
};
</script>
<style lang="scss" scoped>
// refer on src/styles/scss/component/modal.scss
</style>
