import { httpClientOmni } from "@/libraries";
export default {
  async createBroadcast(payload) {
    return await httpClientOmni.post("v3/broadcast/whatsapp/create", payload);
  },
  deleteBroadcast(payload) {
    return httpClientOmni.get("v3/broadcast/whatsapp/delete", {
      params: {
        id: payload.id,
      },
    });
  },
  async editBroadcast(payload) {
    return await httpClientOmni.post("v3/broadcast/whatsapp/edit", payload);
  },
  async exportCsv(payload) {
    return await httpClientOmni.post(
      `v3/broadcast/whatsapp/export-csv`,
      payload,
      {
        responseType: "blob",
      }
    );
  },
  async exportPdf(payload) {
    return await httpClientOmni.post(
      `v3/broadcast/whatsapp/export-pdf`,
      payload
    );
  },
};
