import * as Types from "./mutation-types";
import { httpClientBot } from "@/libraries";
// // import notification from "@/helpers/EventBus";

export const setLoading = ({ commit }) => commit(Types.SET_LOADING);
export const setError = ({ commit }) => commit(Types.SET_ERROR);

export const createVoiceReplaceSuccess = ({ commit }) =>
  commit(Types.RECEIVE_CREATE_VOICE_REPLACE_SUCCESS);

export const receiveFetchTrashbinVoiceReplaceSuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_FETCH_TRASHBIN_VOICE_REPLACE_SUCCESS, payload);

export const createVoiceReplace = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .post("/word-replaces", payload)
    .then(() => {
      this.emitter.emit("flash-success", "Successfully create voice replace");
    })
    .catch(() => {
      this.emitter.emit("flash-error", "Failed create voice replace");
    })
    .finally(() => {
      dispatch("setLoading");
    });
};

export const deleteVoiceReplace = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .delete("/word-replaces", {
      params: {
        wordReplaceCheckBox: JSON.stringify([payload.hashed_id]),
      },
    })
    .then(() => {
      this.emitter.emit("flash-success", "Successfully delete voice replace");
    })
    .catch(() => {
      this.emitter.emit("flash-error", "Failed create delete voice replace");
    })
    .finally(() => {
      dispatch("setLoading");
    });
};

export const editVoiceReplace = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .put(`/word-replaces/${payload.hashed_id}`, {
      word: payload.word,
      replace_word: payload.replace_word,
    })
    .then(() => {
      this.emitter.emit("flash-success", "Successfully edit voice replace");
    })
    .catch(() => {
      this.emitter.emit("flash-error", "Failed create edit voice replace");
    })
    .finally(() => {
      dispatch("setLoading");
    });
};

export const fetchTrashbin = ({ dispatch }, _) => {
  console.log(_);
  dispatch("setLoading");
  return httpClientBot
    .get("/word-replace/trash")
    .then((response) =>
      dispatch("receiveFetchTrashbinVoiceReplaceSuccess", response.data)
    )
    .catch(() => dispatch("setLoading"))
    .finally(() => dispatch("setLoading"));
};

export const restoreVoiceReplace = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .post(`/word-replaces/${payload}/restore`)
    .then(() => {
      this.emitter.emit("flash-success", "Successfully restore voice replace");
    })
    .catch(() => {
      this.emitter.emit("flash-error", "Failed restore  voice replace");
    })
    .finally((_) => console.log(_), dispatch("setLoading"));
};
export const massDeleteVoiceReplace = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .delete("/word-replaces", {
      params: {
        wordReplaceCheckBox: JSON.stringify([payload]),
      },
    })
    .then(() => {
      this.emitter.emit("flash-success", "Successfully delete voice replace");
    })
    .catch(() => {
      this.emitter.emit("flash-error", "Failed create delete voice replace");
    })
    .finally(() => {
      dispatch("setLoading");
    });
};
