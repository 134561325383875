import { httpClientAuth, httpClientOmni } from "@/libraries";

export default {
  async getUserApp(payload) {
    const response = await httpClientAuth.get(`apps/${payload}`);
    return response;
  },
  async getAppData() {
    const response = await httpClientOmni.get("app");
    return response;
  },
};
