import { httpClientOmni, httpPlain } from "@/libraries";
import Hashids from "hashids";
// console.log("url baru", httpClientOmni);
export default {
  async getBot() {
    return await httpClientOmni.get("/bot");
  },
  async getActiveBot(appId) {
    return await httpPlain.get("/bot-active?appId=" + appId);
  },
  async createBot(data) {
    return await httpClientOmni.post("/bot", data);
  },
  async updateBot(data) {
    return await httpClientOmni.post("/update/bot", data);
  },
  async deleteBot(id) {
    let hashids = new Hashids("", 6);
    var hashedBotId = hashids.encode(id);

    return await httpClientOmni.delete("/bot?id=" + hashedBotId);
  },
  async activateBot(data) {
    return await httpClientOmni.post("/bot/activate", data);
  },
};
