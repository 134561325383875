<template>
  <div>
    <div v-for="item in items.data" :key="item">
      <slot name="item" v-bind:item="item" />
    </div>
    <!-- <div v-observe-visibility="handleToScrolledToBottom">
      <div class="text-center p-4" v-if="items.total < per_page">
        <b-spinner small label="Spinning" variant="primary"></b-spinner>
      </div>
    </div> -->
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
export default {
  props: {
    items: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      per_page: 10,
    };
  },
  mounted() {
    console.log("fetch", this.items);
  },
  methods: {
    // async handleToScrolledToBottom(isVisible) {
    //   if (!isVisible) {
    //     return;
    //   }
    //   console.log("notiflist", this.items);
    //   if (this.per_page > this.items.total) {
    //     return;
    //   }
    //   this.per_page += 10;
    //   let payloadNotif = {
    //     user_id: this.$store.getters.localStorage.decodedUserId,
    //     per_page: this.per_page,
    //   };
    //   try {
    //     await this.$store.dispatch("app/getNotif", payloadNotif);
    //   } catch (error) {
    //     console.log("error", error);
    //   }
    //   // this.$emit("refetch", this.per_page);
    // },
  },
};
</script>
