import { httpClientOmni } from "@/libraries";

export default {
  async findGroupOnly(data) {
    return await httpClientOmni.get(`/v3/group-only?filterText=${data}`);
  },
  get() {
    return httpClientOmni.get(`/v3/group`);
  },
  getGroupWithAgent(params) {
    return httpClientOmni.get(`/v3/group`, {
      params,
    });
  },
  getGroupAgent() {
    return httpClientOmni.get(`/v3/group/get-agent`);
  },
};
