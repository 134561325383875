import { httpClientAuth, httpClientOmni } from "@/libraries";
import { transFormer } from "@/utils";

export default {
  async update(param, data) {
    const response = await httpClientAuth.post(`/users/${param}/update`, data);
    return transFormer.response(response);
  },
  async updateMfa(param, data) {
    const response = await httpClientAuth.post(
      `/users/${param}/updateMfa`,
      data
    );
    return transFormer.response(response);
  },
  async block(data) {
    const response = await httpClientOmni.post(`/block`, data);
    return response;
  },
  async unBlock(data) {
    const response = await httpClientOmni.post(`/unblock`, data);
    return response;
  },
};
