<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card-header d-flex align-items-center">
        <h4 class="card-title flex-grow-1 mb-0">Line Broadcast</h4>
        <div class="flex-shrink-0">
          <a
            class="btn btn-soft-success btn-sm"
            href="javascript:void(0);"
            @click="showModalBroadcast()"
            >New Broadcast</a
          >
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive table-card">
          <div class="gridjs-wrapper" style="height: auto">
            <base-table
              :columns="columns"
              :url="`/broadcast-by-channel/broadcast-list/line`"
              :moduleType="'omni'"
              :use-row-selection="false"
              :method="'get'"
            >
              <template #slot-column-0="{ rowData }">
                {{ rowData.topics }}
              </template>
              <template #slot-column-1="{ rowData }">
                {{ rowData.send_by.name }}
              </template>
              <template #slot-column-2="{ rowData }">100%</template>
              <template #slot-column-3="{ rowData }">sent</template>
              <template #slot-column-4="{ rowData }">
                {{ rowData.created_at }}
              </template>
            </base-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalLine
    :data="form"
    :showModal="show.broadcast"
    @onClose="show.broadcast = false"
    @onSubmit="onSubmit"
    title="Line Broadcast"
  />
</template>
<script>
import { nextTick } from "vue";
import { mapGetters, mapActions } from "vuex";
import ModalLine from "./LineModal.vue";
export default {
  components: {
    ModalLine,
  },
  data() {
    return {
      show: {
        broadcast: false,
      },
      form: {},
      columns: [
        {
          title: "Broadcast Name",
          dataIndex: "name",
          key: 0,
          sorter: true,
          width: "35%",
          ellipsis: true,
        },
        {
          title: "Brodcast By",
          // dataIndex: "value",
          key: 1,
          width: "20%",
        },
        {
          title: "Progress",
          // dataIndex: "decription",
          width: "20%",
          key: 2,
        },
        {
          title: "Status",
          // dataIndex: "user_create.name",
          key: 3,
          width: "10%",
        },
        {
          title: "Created at",
          // dataIndex: "user_create.name",
          key: 4,
          width: "15%",
        },
      ],
    };
  },
  async created() {
    this.getChannel("");
    this.getIntegrations("line");
  },
  computed: {
    ...mapGetters({
      channels: "customers/channels",
    }),
  },
  methods: {
    ...mapActions({
      getChannel: "customers/getChannels",
      getIntegrations: "integration/getIntegrationChannel",
      sendBroadcast: "broadcastByChannel/sendBroadcastLine",
    }),
    async onSubmit(data) {
      console.log("broadcast button clicked");
      console.log(data);
      this.form = data;
      await this.create(); // send to app project
      await nextTick();
      console.log("done yeeee");
    },
    showModalBroadcast() {
      this.form = false; // reset
      this.show.broadcast = true;
      console.log("modal broadcast is showing");
    },
    async create() {
      try {
        console.log("create :", this.form);
        let response = await this.sendBroadcast(this.form); // send broadcast.
        console.log("balikan data:", response);
        this.show.broadcast = false;
        this.successToast("your integration has been successfully send.");
      } catch (error) {
        console.log("error data:".error);
        this.errorToast("Oops! Your broadcast could not be send.");
      }
    },
  },
};
</script>
<style scoped></style>
