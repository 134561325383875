import moment from "moment";

const Types = {
  SET_FILTER: "SET_FILTER",
  SET_ERROR: "SET_ERROR",
  SET_LOADING: "SET_LOADING",
};

const state = {
  filter: {
    startDate: moment().subtract(7, "d").format("YYYY-MM-DD HH:mm"),
    endDate: moment().format("YYYY-MM-DD HH:mm"),
    sort: "id|desc",
  },
  error: null,
  loading: false,
};

const getters = {
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  filter(state) {
    return state.filter;
  },
};

const mutations = {
  [Types.SET_FILTER](state, payload) {
    state.filter.startDate = payload.startDate;
    state.filter.endDate = payload.endDate;
    state.filter.sort = payload.sort;
  },
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.SET_ERROR]: (state, payload) => (state.error = payload),
};

const actions = {
  setFilter({ commit }, payload) {
    commit(Types.SET_FILTER, payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
