import { httpClientOmni } from "@/libraries";
export default {
  async assignToGroup(payload) {
    return await httpClientOmni.post("livechat/assign-group", {
      ...payload,
    });
  },
  async assignToAgent(payload) {
    return await httpClientOmni.post("livechat/assign-agent", {
      ...payload,
    });
  },
  async livechatResolve(payload) {
    return await httpClientOmni.post("livechat/resolve", {
      ...payload,
    });
  },
  async livechatRequest(payload) {
    return await httpClientOmni.post("livechat/request", {
      ...payload,
    });
  },
  async livecall(payload) {
    let status = payload.status;
    let data = await httpClientOmni.post(`/v3/livecall/${status}`, {
      ...payload,
    });

    return data;
  },
};
