import { AuthService } from "@/services";
const state = {
  listProject: [],
};
const getters = {};
const mutations = {
  SET_PROJECT_LIST: (state, payload) => {
    state.listProject = payload;
  },
};
const actions = {
  getProject() {
    return AuthService.getProject();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
