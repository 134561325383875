import { httpClientBot } from "@/libraries";

export default {
  async getStoryGroupsCard() {
    return await httpClientBot.get("/story-groups/card");
  },
  async deleteStoryGroups(hashedId) {
    return await httpClientBot.delete(
      `/story-groups/delete?storyGroupCheckBox=["${hashedId}"]`
    );
  },
  async createStoryGroup(payload) {
    return await httpClientBot.post("story-groups/create", payload);
  },
  async updateStoryGroup(payload) {
    return await httpClientBot.put(
      `story-groups/${payload.hashed_id}`,
      payload
    );
  },
  async getTrashBinList() {
    return await httpClientBot.get("/story-groups/trash");
  },
  async restoreTrashBin(id) {
    return await httpClientBot.post(`/story-groups/${id}/restore`);
  },
  async detailStoryGroup(id) {
    return await httpClientBot.get(`/story-groups/${id}/edit`);
  },
};
