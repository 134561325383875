import * as Types from "./mutation-types";
import { httpClientBot } from "@/libraries";
import { InteractionService } from "@/services";
import Toast from "@/helpers/toast.helper";
// // import notification from "@/helpers/EventBus";

export const setLoading = ({ commit }) => commit(Types.SET_LOADING);
export const setError = ({ commit }) => commit(Types.SET_ERROR);

export const receiveInteractionSuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_INTERACTION_SUCCESS, payload);
export const receiveFunctionlistApiSuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_FUNCTIONLIST_API_SUCCESS, payload);
export const receiveFunctionlistFunctionSuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_FUNCTIONLIST_FUNCTION_SUCCESS, payload);
export const receiveEntitiesSuccess = ({ commit }, payload) =>
  commit(Types.RECEVIE_ENTITIES_SUCCESS, payload);
export const receiveSysEntitiesSuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_SYS_ENTITIES_SUCCESS, payload);
export const receiveChannelSuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_CHANNELS_SUCCESS, payload);
export const receiveStoryGroupSuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_STORY_GROUP_SUCCESS, payload);
export const receiveStorySuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_STORY_SUCCESS, payload);
export const setInteraction = ({ commit }, payload) =>
  commit(Types.SET_INTERACTION, payload);
export const setMergedEntities = ({ commit }, payload) =>
  commit(Types.SET_MERGED_ENTITIES, payload);
export const setSelectedInteraction = ({ commit }, payload) =>
  commit(Types.SET_SELECTED_INTERACTION, payload);
export const receiveStoriesSuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_STORIES_SUCCESS, payload);
export const receiveInteractionListSuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_INTERACTION_LIST_SUCCESS, payload);

export const fetchStories = ({ dispatch }) => {
  dispatch("setLoading");
  return httpClientBot
    .get(`/stories`)
    .then((response) => {
      dispatch("receiveStoriesSuccess", response.data);
    })
    .catch(() => {
      dispatch("setError");
    })
    .finally(() => dispatch("setLoading"));
};

export const fetchAttributes = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .get(`/stories/${payload}/attributes`)
    .then((response) => {
      let data = response.data;
      // set interactions
      dispatch("receiveInteractionSuccess", data.stories.interaction);
      dispatch("receiveInteractionListSuccess", data.stories.interaction);
      // set entity
      let entities = data.entities.data;
      let updatedEntities = entities.map((element) => element.entity_name);

      dispatch("receiveEntitiesSuccess", updatedEntities);

      let sysEntities = data.sys_entities;
      let updateSysEntities = sysEntities.map(
        (element) => `${element.prefix}${element.type}.${element.name}`
      );
      // set sys entity
      dispatch("receiveSysEntitiesSuccess", updateSysEntities);

      // set mergedEntities
      dispatch("setMergedEntities", [...updatedEntities, ...updateSysEntities]);

      // set function list function
      dispatch(
        "receiveFunctionlistFunctionSuccess",
        data.functionlist_function
      );
      // set function list api
      dispatch("receiveFunctionlistApiSuccess", data.functionlist_api);
      // set story group
      dispatch("receiveStoryGroupSuccess", data.story_group);
      // set story
      dispatch("receiveStorySuccess", data.stories);
      // set channel
      dispatch("receiveChannelSuccess", data.channels);
    })
    .catch(() => {
      dispatch("setError");
    })
    .finally(() => dispatch("setLoading"));
};

export const fetchInteractions = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .get(`/story/${payload}/interactions/`)
    .then((response) => {
      dispatch("receiveInteractionListSuccess", response.data);
    })
    .catch(() => {
      dispatch("setError");
    })
    .finally(() => dispatch("setLoading"));
};

export const updateInteractionSetting = (
  { dispatch },
  { param, request_body }
) => {
  dispatch("setLoading");
  return httpClientBot
    .put(`/stories/${param}`, request_body)
    .then(() => {
      Toast.success("Success update interaction setting");
    })
    .catch(() => {
      dispatch("setError");
    })
    .finally(() => dispatch("setLoading"));
};

export const createInteraction = async ({ dispatch }, payload) => {
  dispatch("setLoading");
  try {
    const { data: response } = await InteractionService.createInteraction(
      payload
    );
    if (response.success) {
      return response.data;
    }
  } catch (error) {
    console.log("err", error);
    dispatch("setError");
  }
  // return httpClientBot
  //   .post("/interactions", payload)
  //   .then(() => {
  //     Toast.success("Success create interaction");
  //   })
  //   .catch(() => dispatch("setError"))
  //   .finally(() => dispatch("setLoading"));
};

export const deleteInteraction = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .delete(`/interactions/${payload}`)
    .then(() =>
      // Toast.success("Success delete interaction")
      Toast.success("Success delete interaction")
    )
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};

export const openInteraction = ({ dispatch }, payload) => {
  dispatch("setSelectedInteraction", payload);
};

export const simulateAPI = ({ dispatch }, payload) => {
  dispatch("setLoading");

  let requestBody = {
    url: payload.url,
    body: payload.body,
    header: payload.header,
    method: payload.method,
  };

  return httpClientBot
    .post("/interactions/simulate-api", requestBody)
    .then((response) => {
      return JSON.stringify(response.data, null, 2);
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const checkInteraction = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .post("/interactions/check", payload)
    .then((response) => {
      if (response.data.success === false) {
        Toast.error(response.data.message);
        // this.emitter.emit("flash-error", response.data.message);
        return false;
      } else {
        // Toast.success(response.data.message);
        return true;
      }
    })
    .catch(() => {
      dispatch("setError");
      return false;
    })
    .finally(() => dispatch("setLoading"));
};

export const updateInteraction = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .put(`/interactions/${payload.interactionId}`, payload.requestBody)
    .then((response) => {
      Toast.success(response.data.message);
      return true;
    })
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};

export const trainBot = ({ dispatch }) => {
  dispatch("setLoading");
  return httpClientBot
    .post("/train-bot")
    .then((response) => {
      Toast.success(response.data.message);
      return response.data;
    })
    .catch((response) => {
      Toast.success(response.data.message);
      dispatch("setError");
      return response.data;
    })
    .finally(() => dispatch("setLoading"));
};

export const massDeleteStories = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .delete(`/stories/massdelete?storyCheckBox=["${payload}"]`)
    .then(() => Toast.success("Successfully delete story"))
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};

export const updateNode = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .post(`/interactions/update-node/${payload.interactionId}`, payload)
    .then(() => Toast.success("Successfully update node"))
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};
