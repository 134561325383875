import { drop, find, flattenDeep, reject, findIndex } from "lodash";
import { ProjectService } from "@/services";
import { httpClientOmni } from "@/libraries";

const state = {
  app: {},
  userApp: [],
  activeApp: "List Project",
  recentSearches: localStorage.getItem("lenna_recent_searches") ?? [],
  notif: [],
  notifList: [],
  unread_notif_count: "",
  tickets: [],
  customers: [],
  search_loading: false,
  search_text: "",
};

const getters = {
  getStatusApprovalAgent(state) {
    return state.app.approval_agent;
  },
  getStatusAutoSendRatingMessage(state) {
    return state.app.auto_send_rating_message;
  },
  getTriggerAutoSendRating(state) {
    if (state.app.trigger_auto_rating == "true") {
      return true;
    } else {
      return false;
    }
  },
  app(state) {
    return state.app;
  },
  userApp(state) {
    return state.userApp;
  },
  tickets(state) {
    return state.tickets;
  },
  customers(state) {
    return state.customers;
  },
  activeApp(state) {
    return state.activeApp;
  },
  searchLoading(state) {
    return state.search_loading;
  },
  searchText(state) {
    return state.search_text;
  },
  recentSearches() {
    if (localStorage.getItem("lenna_recent_searches")) {
      return flattenDeep(
        JSON.parse(localStorage.getItem("lenna_recent_searches"))
      );
    }
  },
  notifList(state) {
    return state.notifList;
  },
  unread_notif_count(state) {
    return state.unread_notif_count;
  },
};
const mutations = {
  SET_APP: (state, payload) => {
    state.app = { settings: { ...payload.settings }, ...payload };
  },
  SET_USER_APP: (state, payload) => {
    state.userApp = payload;
  },
  TICKETS: (state, payload) => {
    state.tickets = payload;
  },
  CUSTOMERS: (state, payload) => {
    state.customers = payload;
  },
  SEARCH_LOADING: (state, payload) => {
    state.search_loading = payload;
  },
  SEARCH_TEXT: (state, payload) => {
    state.search_text = payload;
  },
  SET_ACTIVE_APP: (state, payload) => {
    let data =
      find(state.userApp, (o) => {
        return o.hashed_id == payload;
      }).name || "loading";
    state.activeApp = data;
  },
  ADD_RECENT_SEARCHES: (state, payload) => {
    let data = [];
    if (localStorage.getItem("lenna_recent_searches")) {
      const s = JSON.parse(localStorage.getItem("lenna_recent_searches"));
      data = flattenDeep(s);
      data.push(payload);
      flattenDeep(data);
      if (data.length > 5) {
        data = drop(data);
      }
    }
    localStorage.setItem("lenna_recent_searches", JSON.stringify(data));
  },
  SET_NOTIF_LIST: (state, payload) => {
    state.notifList = payload;
  },
  SELECT_NOTIF: (state, payload) => {
    const index = state.notifList.findIndex((item) => item.id == payload.id);
    state.notifList.splice(index, 1);
  },
  UPDATE_NOTIF_LIST: (state, payload) => {
    let isExist = find(state.notifList, function (obj) {
      return obj.id == payload.id;
    });
    if (isExist) {
      // is notif exist
      let notifIndex = findIndex(state.notifList, function (obj) {
        return obj.id == payload.id;
      });

      let prevUnreadCount = state.notifList[notifIndex].unread_count;
      state.notifList[notifIndex].items.push(payload.items[0]);

      state.notifList[notifIndex].unread_count = prevUnreadCount + 1;

      return;
    } else {
      // if doesnt exist
      state.notifList.push(payload);
    }
  },
  UNREAD_NOTIF_COUNT: (state) => {
    // let data = state.notifList.data;
    // function filterNotifUnread(item) {
    //   return item.read == false;
    // }
    // let filterArray = data.filter(filterNotifUnread);
    // state.unread_notif_count = filterArray.length;
    let data = state.notifList;
    state.unread_notif_count = data.total_unread;
  },
  READ_NOTIF: (state, payload) => {
    let notifications = state.notifList;
    let unreadNotif = reject(notifications, function (o) {
      return o.data.room.id == payload.id;
    });
    state.notifList = unreadNotif;
  },
  NEW_NOTIF: (state, payload) => {
    state.notifList.data.unshift(payload);
    state.unread_notif_count = state.unread_notif_count + 1;
  },
};
const actions = {
  async getAppData(context) {
    const { data: response } = await ProjectService.getAppData();
    context.commit("SET_APP", response.data.app);
  },
  async getUserApp(context, payload) {
    try {
      const response = await ProjectService.getUserApp(payload);
      context.commit("SET_USER_APP", response.data);
      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  },
  updateAppData(context, payload) {
    context.commit("SET_APP", payload);
  },
  getActiveApp(context, payload) {
    context.commit("SET_ACTIVE_APP", payload);
  },
  async getNotifList(context, rootGetters) {
    let user_id = rootGetters.localStorage.decodedUserId;
    console.log("user_id", user_id);
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/app/get-notif`,
      {
        user_id: user_id,
      }
    );
    console.log("notif", response);
    context.commit("SET_NOTIF_LIST", response);
  },
  async getNotif(context, payload) {
    let user_id = payload.user_id;
    let per_page = payload.per_page;
    try {
      let response = await httpClientOmni.get(
        `notification/get-notif?user_id=${user_id}&per_page=${per_page}`
      );
      context.commit("SET_NOTIF_LIST", response.data);
      context.commit("UNREAD_NOTIF_COUNT");
    } catch (err) {
      // commit(Types.ERROR_SERVICE, err);
      console.log("error response", err);
    }
  },
  async readNotifList(context, payload) {
    try {
      let response = await httpClientOmni.post(`notification/read`, {
        id: payload,
      });
      context.commit("SET_NOTIF_LIST", response.data);
      context.commit("UNREAD_NOTIF_COUNT");
    } catch (err) {
      // commit(Types.ERROR_SERVICE, err);
      console.log("error response", err);
    }
  },
  updateNotifList(context, payload) {
    context.commit("UPDATE_NOTIF_LIST", payload);
  },
  selectNotif(context, payload) {
    context.commit("SELECT_NOTIF", payload);
  },
  readNotif(context, payload) {
    context.commit("READ_NOTIF", payload);
  },
  newNotif(context, payload) {
    context.commit("NEW_NOTIF", payload);
  },
  searchLoading(context, payload) {
    context.commit("SEARCH_LOADING", payload);
  },
  async search(context, payload) {
    try {
      context.commit("SEARCH_TEXT", payload.filterText);
      context.commit("SEARCH_LOADING", true);
      const { data: response } = await httpClientOmni.post(
        `v3/search`,
        payload
      );

      let tickets = response.data.tickets.filter((o, k) => k < 5);
      let customers = response.data.customers.filter((o, k) => k < 5);

      context.commit("TICKETS", tickets);
      context.commit("CUSTOMERS", customers);
      context.commit("SEARCH_LOADING", false);
    } catch (err) {
      // commit(Types.ERROR_SERVICE, err);
      console.log("error response", err);
    }
  },
};
export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
