const parkingRoute = [
  // DASHBOARD
  {
    path: "/:appId/parking",
    component: () => import("@/views/parking/pages/Dashboard.vue"),
    redirect: {
      name: "parking.parking-dashboard",
    },
  },
  {
    path: "/:appId/parking/dashboard",
    name: "parking.parking-dashboard",
    component: () => import("@/views/parking/pages/Dashboard.vue"),
    redirect: {
      name: "parking.parking-dashboard.main",
    },
    meta: {
      title: "Dashboard Parking",
    },
  },
  {
    path: "/:appId/parking/dashboard/main",
    name: "parking.parking-dashboard.main",
    component: () => import("@/views/parking/pages/Dashboard.vue"),
    meta: {
      title: "Dashboard Parking",
    },
  },
  // MANAGEMENT
  {
    path: "/:appId/parking/management",
    name: "parking.parking-management",
    component: () => import("@/views/parking/pages/Dashboard.vue"),
    redirect: {
      name: "parking-management.parking-area",
    },
  },
  {
    path: "/:appId/parking/management/area",
    name: "parking.parking-management.parking-area",
    component: () => import("@/views/parking/pages/Dashboard.vue"),
  },
  {
    path: "/:appId/parking/management/devices",
    name: "parking.parking-management.device-management",
    component: () => import("@/views/parking/pages/ParkingDevice.vue"),
    meta: {
      title: "Devices",
    },
  },
  {
    path: "/:appId/parking/management/fares",
    name: "parking.parking-management.fare-management",
    component: () => import("@/views/parking/pages/Dashboard.vue"),
  },
  // CUSTOMER
  {
    path: "/:appId/parking/customers",
    name: "parking.parking-customers",
    component: () => import("@/views/parking/pages/CustomerList.vue"),
    redirect: {
      name: "parking-customers.list",
    },
    meta: {
      title: "Customer List",
    },
  },
  {
    path: "/:appId/parking/customers/list",
    name: "parking.parking-customers.list",
    component: () => import("@/views/parking/pages/CustomerList.vue"),
    meta: {
      title: "Customer List",
    },
  },
  // REPORTS
  {
    path: "/:appId/parking/reports",
    name: "parking.parking-reporting",
    component: () => import("@/views/parking/pages/Dashboard.vue"),
    redirect: {
      name: "parking-reporting.transaction",
    },
  },
  {
    path: "/:appId/parking/reports/transaction",
    name: "parking.parking-reporting.transaction",
    component: () => import("@/views/parking/pages/ReportTransaction.vue"),
    meta: {
      title: "Transaction Orders",
    },
  },
  {
    path: "/:appId/parking/reports/parking",
    name: "parking.parking-reporting.parking",
    component: () => import("@/views/parking/pages/ReportParking.vue"),
    meta: {
      title: "Reporting Parking",
    },
  },
];
export default parkingRoute;
