import { httpClientOmni } from "@/libraries";
export default {
  async getDevice(payload) {
    if (payload) {
      return await httpClientOmni.get("/parking/device", {
        params: { text: payload.filter },
      });
    } else {
      return await httpClientOmni.get("/parking/device");
    }
  },
  async createDevice(data) {
    return await httpClientOmni.post("/parking/device", data);
  },
  async updateDevice(data) {
    return await httpClientOmni.put("/parking/device", data);
  },
  async deleteDevice(id) {
    return await httpClientOmni.delete("/parking/device?id=" + id);
  },
  async syncDevice() {
    return await httpClientOmni.post("/parking/device-sync");
  },
  async commandDevice(payload) {
    return await httpClientOmni.post("/parking/command-device", payload);
  },
};
