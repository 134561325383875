const billingRoute = [
  {
    path: "/:appId/billing",
    name: "billing",
    component: () => import("@/views/billing/pages/BillingMain.vue"),
    meta: {
      title: "Billing",
    },
  },
];
export default billingRoute;
