import { httpClientOmni } from "@/libraries";

export default {
  async find(payload) {
    var endpoint = "tag";
    if (payload) {
      endpoint = `tag?filterText=${payload}`;
    }
    const response = await httpClientOmni.get(endpoint);
    return response;
  },
  async findAll() {
    const response = await httpClientOmni.get(`tag-all`);
    return response;
  },
  async store(data) {
    const response = await httpClientOmni.post(`tag`, data);
    return response;
  },
  async destroy(param) {
    const response = await httpClientOmni.get(`tag/${param}/delete`);
    return response;
  },
  async update(param, data) {
    const response = httpClientOmni.put(`tag/${param}`, data);
    return response;
  },
  async search(param) {
    const response = httpClientOmni.get(`tag/get-ticket?filterText=${param}`);
    return response;
  },
};
