<template>
  <div class="col pt-4 px-3 bg-greenish">
    <h4 style="color: #5588c3" class="mt-4">Chat bot Integration</h4>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="form-group col-md-10 mt-2">
          <br />
          <p>Select a Bot that you want to connect.</p>
        </div>

        <div class="form-group col-md-10">
          <select
            style="background: #f3f3f3"
            name="bot"
            class="form-control"
            v-model="form.botId"
          >
            <option :value="bot.id" v-for="(bot, key) in botList" :key="key">
              {{ bot.name }}
            </option>
          </select>
          <small>Select your own bot</small>
        </div>

        <div class="form-group col-md-10 ml-1 mt-5 row">
          <input type="hidden" v-model="form.status" />
          <div>
            <button
              style="width: 150px; border-radius: 8px"
              type="button"
              class="btn mr-3"
              :class="!form.status ? 'btn-secondary' : 'btn-danger'"
              :disabled="!form.status"
              @click="disconnectChannel"
            >
              Disconnect
            </button>
          </div>
          <div>
            <button
              style="
                background: #2787c4 !important;
                width: 150px;
                border-radius: 8px;
              "
              type="submit"
              class="btn btn-primary"
            >
              {{ form.status ? "Update" : "Connect" }}
            </button>
          </div>
        </div>

        <hr />
      </div>
    </form>
    <lenna-modal ref="lennaModal"></lenna-modal>
  </div>
</template>

<script>
import Toast from "@/helpers/toast.helper";
import Hashids from "hashids";

export default {
  data() {
    return {
      form: {
        botId: 0,
        status: false,
        channelName: "bot",
      },
      botList: [],
      user: this.$store.getters.localStorage.user,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    // hideModal () {
    //   this.$refs.modalInfo.hide()
    // },
    async disconnectChannel() {
      try {
        let data = {
          channelName: this.form.channelName,
          status: false,
        };
        // eslint-disable-next-line no-unused-vars
        let response = await this.$axios.put(
          `api/${this.$store.getters.appId.hashed}/integrate/update`,
          data
        );
        response = response.data;

        this.emitter.emit("hideIntegrationModal");
        Toast.success("Channel disconected");
        this.form.status = false;
      } catch (error) {
        this.form.status = !this.form.status;
        Toast.error(error);
      }
    },
    async submitForm() {
      let validated = await this.$validator.validateAll();
      let hashids = new Hashids("", 6);
      if (validated) {
        if (this.form.botId != 0) {
          try {
            let formData = { ...this.form, status: true };
            // let formData = {
            //   botId: hashids.decode(this.form.botId)[0],
            //   status: true,
            //   channelName: this.form.channelName
            // };
            let response = await this.$axios.post(
              `api/${this.$store.getters.appId.hashed}/integrate`,
              formData
            );
            this.form.status = true;
            const hashedBotId = hashids.encode(formData.botId);
            this.$store.dispatch("bot/setBotId", hashedBotId);
            this.emitter.emit("hideIntegrationModal");
            Toast.success(response.data.message);
          } catch (err) {
            if (err) {
              if (err.response.status == 403) {
                return Toast.error(err);
              }
              return Toast.error(err);
            }
          }
        }
      } else {
        Toast.error("you must fill the form first");
      }
    },
    async fetchData() {
      let response = await this.$axios.get(
        `api/${this.$store.getters.appId.hashed}/integrate`
      );
      response = response.data;
      let channelData = response.data.find(function (item) {
        return item.name == "bot";
      }).integration;

      if (channelData !== null) {
        this.form.botId = channelData.integration_data.botId;
        this.form.status = channelData.status;
      }
    },
  },
  async mounted() {
    this.fetchData();
    let response = await this.$axios.get(
      `/api/bot/get-user-bot/${this.user.id}`
    );
    this.botList = response.data;
  },
};
</script>

<style lang="scss" scoped>
.bg-class {
  position: relative;
  float: right;
  background-color: #fafafa;
  min-width: 1000px;
  min-height: 750px;
  height: auto;
}
.img-prepend {
  position: relative;
  float: right;
  right: 15px;
  bottom: 28px;
  width: 20px;
  height: 20px;
}
</style>
