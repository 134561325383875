<template>
  <b-modal
    hide-footer
    hide-header
    no-padding
    class="border-0"
    modal-class="lenna-modal"
    body-class="p-0 m-0 border-0"
    content-class="border-0"
    size="lg"
    centered
    v-model="isOpen"
    v-bind="$attrs"
    @hide="handleCloseModal"
    @hidden="handleCloseModal"
  >
    <slot name="modal-header">
      <div
        class="ps-4 pe-3 py-2 bg-primary d-flex justify-content-between rounded-top"
      >
        <h5 class="modal-title text-light my-auto">{{ modalTitle }}</h5>
        <i
          class="ri-close-line text-white fs-4 pointer"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="handleCloseModal"
          v-if="!hideCloseIcon"
        ></i>
      </div>
    </slot>
    <div class="container py-3 px-4">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-lg-12">
            <div class="row g-3">
              <!-- IntegrationName -->
              <div class="col-lg-12">
                <div>
                  <label for="tasksTitle-field" class="form-label"
                    >Integration Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="your tokopedia integration name"
                    required
                    v-model="formData.IntegrationName"
                  />
                </div>
              </div>
              <!-- appId -->
              <div class="col-lg-12">
                <div>
                  <label for="tasksTitle-field" class="form-label"
                    >APP ID</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="your app id"
                    required
                    v-model="formData.appId"
                  />
                </div>
              </div>
              <!-- shopId -->
              <div class="col-lg-12">
                <div>
                  <label for="tasksTitle-field" class="form-label"
                    >Shop ID</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="your shop id"
                    required
                    v-model="formData.shopId"
                  />
                </div>
              </div>
              <!-- clientId -->
              <div class="col-lg-12">
                <div>
                  <label for="tasksTitle-field" class="form-label"
                    >Client ID</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="your client id"
                    required
                    v-model="formData.clientId"
                  />
                </div>
              </div>
              <!-- clientSecret -->
              <div class="col-lg-12">
                <div>
                  <label for="tasksTitle-field" class="form-label"
                    >Client Secret</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="your client secret"
                    required
                    v-model="formData.clientSecret"
                  />
                </div>
              </div>
              <!-- bott -->
              <div class="col-lg-12 mt-3">
                <div>
                  <label class="form-label">Bot</label>
                  <v-select
                    label="name"
                    name="bot"
                    class="bg-white"
                    :options="botList"
                    :placeholder="`Select bot`"
                    v-model="bot"
                    @option:selected="changeValueBotList"
                  />
                </div>
              </div>
              <!-- business unit -->
              <div class="col-lg-12 mt-3">
                <div>
                  <label class="form-label">Business Unit</label>
                  <v-select
                    label="label"
                    name="bussines_unit"
                    class="bg-white"
                    :options="businessUnit"
                    :placeholder="`Select bussines unit`"
                    v-model="business_unit"
                    @option:selected="changeValueBusinessUnit"
                  />
                </div>
              </div>
              <!-- group -->
              <div class="col-lg-12 mt-3">
                <div>
                  <label class="form-label">Groups</label>
                  <v-select
                    label="label"
                    name="group_management"
                    class="bg-white"
                    :options="groupManagement"
                    :placeholder="`Select group`"
                    v-model="group"
                    @option:selected="changeValueGroupManagement"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 hstack gap-2 justify-content-end">
          <base-button type="submit" :variant="'primary'" :loading="isLoading">
            Submit
          </base-button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { debounce } from "lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
    },
    modalTitle: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    vSelect,
  },
  computed: {
    ...mapGetters({
      botList: "bot/botList",
      businessUnit: "ticket/getBranchOffice",
      groupManagement: "ticket/getGroupManagement",
    }),
    localStorage() {
      return this.$store.getters.localStorage;
    },
  },
  data() {
    return {
      isOpen: this.showModal,
      debounce,
      formData: {},
      business_unit_id: null,
      business_unit: null,
      group_id: null,
      group: null,
      bot_id: null,
      bot: null,
    };
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.onShowModal();
      } else {
        this.onHideModal();
      }
    },
  },
  methods: {
    onShowModal() {
      this.formData = this.form;

      this.business_unit = this.form.business_unit;
      this.group = this.form.group;
      this.bot = this.form.bot;
      console.log("data di dalam modal:", this.formData);
      this.isOpen = true;
    },
    onHideModal() {
      this.isOpen = false;
      this.$emit("onClose");
    },
    changeValueBotList(val) {
      console.log("bot:", val);
      this.bot = val.name;
      this.formData.bot_id = val.id;
      this.formData.bot = val.name;
    },
    changeValueGroupManagement(val) {
      console.log("group:", val);
      this.group = val.label;
      this.formData.group_id = val.value;
      this.formData.group = val.label;
    },
    changeValueBusinessUnit(val) {
      console.log("business_unit:", val);
      this.business_unit = val.label;
      this.formData.business_unit_id = val.id;
      this.formData.business_unit = val.label;
    },
    handleCloseModal() {
      this.$emit("onClose");
    },
    async handleSubmit() {
      console.log("form ", this.formData);
      await this.submit(this.formData, this);
    },
    submit: debounce(async (data, vm) => {
      vm.$emit("onSubmit", data);
    }, 300),
  },
  mounted() {
    this.emitter.on("form:reset", () => {
      this.resetObjectValue(this.formData);
    });
  },
};
</script>

<style>
.img-prepend {
  position: relative;
  float: right;
  right: 15px;
  bottom: 28px;
  width: 20px;
  height: 20px;
}
</style>
