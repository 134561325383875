import { httpClientOmni } from "@/libraries";

export default {
  async addBlockAccessRequest(payload) {
    return await httpClientOmni.post("bni/block/user-request", payload);
  },
  async approveBlockAccessRequest(payload) {
    return await httpClientOmni.post("bni/block/user-approve", payload);
  },
  async unBlockAccess(payload) {
    return await httpClientOmni.post("bni/block/user-unblock", payload);
  },
  async blockTransaction(payload) {
    return await httpClientOmni.post("bni/block/transaction-request", payload);
  },
  async approveBlockTransaction(payload) {
    return await httpClientOmni.post("bni/block/transaction-approve", payload);
  },
  async unBlockTransaction(payload) {
    return await httpClientOmni.post("bni/block/transaction-unblock", payload);
  },
};
