import { httpClientOmni } from "@/libraries";
import Hashids from "hashids";

export default {
  async getMember(params) {
    return await httpClientOmni.get("/v3/member", {
      params,
    });
  },
  async getMemberSearch(value) {
    return await httpClientOmni.get(
      `/v3/member?sort=&page=1&per_page=10&filterText=${value}&status_active=true`
    );
  },
  async getSearchTeamMember(value) {
    return await httpClientOmni.get(
      `/v3/member/search?sort=&page=1&per_page=100&filterText=${value}&status_active=true`
    );
  },
  async getMemberGroup() {
    const response = httpClientOmni.get("/group/get-agent-group");
    return response;
  },
  async updateOnline(data) {
    return await httpClientOmni.post("/agent/change-status", data);
  },
  async getState(data) {
    return await httpClientOmni.post("/agent/get-status", data);
  },
  async rejectAssign(data) {
    return await httpClientOmni.post("/agent/reject-assign", data);
  },
  async assignAgent(data) {
    // return await httpClientOmni.post("/agent/assign", {
    //   agent_id: data.agent.user_id,
    //   room_id: data.room.id,
    //   livechat: data.room.livechat
    // });
    console.log(data);
    return await httpClientOmni.post("/livechat/assign-agent", {
      room_id: data.room.id,
      agent_id: data.agent.user.id,
      assignee: data.agent.user.email,
      assignor: data.assignor,
      assignor_role: data.assignor_role,
    });
  },
  async assignedChannel(data) {
    let hashids = new Hashids("", 6);
    return await httpClientOmni.get(
      "/agent/" + hashids.encode(data) + "/assigned-channel"
    );
  },
  getGroupWithAgent() {
    return httpClientOmni.get(`group?paginated=false`);
  },
  async assignedGroup() {
    const response = httpClientOmni.get("/group/current-agent");
    return response;
  },
};
