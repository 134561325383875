import { WebchatService } from "@/services";

const Types = {
  FETCH_TAGS_SUCCESS: "FETCH_TAGS_SUCCESS",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_LOADING: "SET_LOADING",
  SET_STYLE: "SET_STYLE",
  SET_DATA: "SET_DATA",
};

const state = {
  data: [],
  error: null,
  loading: false,
  style: {},
};

const getters = {
  get(state) {
    return state.data;
  },
  style(state) {
    return state.style;
  },
};

const mutations = {
  [Types.FETCH_TAGS_SUCCESS]: (state, payload) => (state.data = payload),
  [Types.SET_STYLE]: (state, payload) => (state.style = payload),
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.SET_DATA]: (state, payload) => (state.data = payload),
};

const actions = {
  async removeFile({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await WebchatService.removeFile(payload);
      commit(Types.SET_DATA, response);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async create({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await WebchatService.store(payload);
      commit(Types.SET_STYLE, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getStyle({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await WebchatService.getStyle(payload);
      commit(Types.SET_STYLE, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
