import Input from "./Input";
import Modal from "./Modal";
import Select from "./Select";
import Button from "./Button";
import Label from "./Label";
import TextArea from "./TextArea";
import ButtonIcon from "./ButtonIcon";
import Observer from "./Observer";
import MyTable from "./MyTable.vue";
import DynamicForm from "./DynamicForm.vue";
import ModalDelete from "./ModalDelete.vue";
import Uploader from "./Uploader.vue";
import Tiptap from "./Tiptap.vue";
import Filters from "./Filters.vue";
import WidgetCardImageOne from "./WidgetCardImageOne.vue";

export default [
  Button,
  Input,
  Modal,
  Select,
  Label,
  TextArea,
  ButtonIcon,
  Observer,
  MyTable,
  DynamicForm,
  ModalDelete,
  Uploader,
  Tiptap,
  Filters,
  WidgetCardImageOne,
];
