import { DashboardSlaService } from "@/services";

const Types = {
  SET_LIVECHAT_TRAFFICS: "SET_LIVECHAT_TRAFFICS",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_LOADING: "SET_LOADING",
  SET_AGENT_TRAFFICS: "SET_AGENT_TRAFFICS",
  SET_SLA: "SET_SLA",
  SET_AHT: "SET_AHT",
};

const state = {
  error: null,
  loading: false,
  sla: 0,
  ahtAll: "00:00:00",
  livechatTraffics: {
    request: 0,
    live: 0,
    cancel: 0,
    resolved: 0,
  },
  agentTraffics: {
    login: 0,
    aux: 0,
    handling: 0,
    idle: 0,
  },
};

const getters = {
  loading(state) {
    return state.loading;
  },
  livechatTraffics(state) {
    return state.livechatTraffics;
  },
  agentTraffics(state) {
    return state.agentTraffics;
  },
  sla(state) {
    return state.sla;
  },
  ahtAll(state) {
    return state.ahtAll;
  },
};

const mutations = {
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.ERROR_SERVICE]: (state, payload) => (state.error = payload),
  [Types.SET_LIVECHAT_TRAFFICS]: (state, payload) => {
    state.livechatTraffics = payload;
  },
  [Types.SET_AGENT_TRAFFICS]: (state, payload) => {
    state.agentTraffics = payload;
  },
  [Types.SET_SLA]: (state, payload) => (state.sla = payload),
  [Types.SET_AHT]: (state, payload) => (state.ahtAll = payload),
};

const actions = {
  async getLivechatTraffics({ commit }, payload) {
    // console.log("payload", payload);
    commit(Types.SET_LOADING, true);
    try {
      const body = {
        start: payload.start,
        end: payload.end,
        status: payload.status,
      };
      const { data: response } = await DashboardSlaService.getLivechatTraffics(
        body
      );
      if (response.success) {
        commit(Types.SET_LIVECHAT_TRAFFICS, response.data);
      }
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getAgentTraffics({ commit }, payload) {
    // console.log("payload", payload);
    commit(Types.SET_LOADING, true);
    try {
      const body = {
        start: payload.start,
        end: payload.end,
      };
      const { data: response } = await DashboardSlaService.getAgentTraffics(
        body
      );
      if (response.success) {
        commit(Types.SET_AGENT_TRAFFICS, response.data);
      }
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getSla({ commit }, payload) {
    // console.log("payload", payload);
    commit(Types.SET_LOADING, true);
    try {
      const body = {
        start: payload.start,
        end: payload.end,
        status: payload.status,
        channelId: payload.channelId,
      };
      const { data: response } = await DashboardSlaService.getSla(body);
      if (response.success) {
        commit(Types.SET_SLA, response.data);
      }
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getAht({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const body = {
        start: payload.start,
        end: payload.end,
        channelId: payload.channelId,
      };
      const { data: response } = await DashboardSlaService.getAht(body);
      if (response.success) {
        commit(Types.SET_AHT, response.data);
      }
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
