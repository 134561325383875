export default {
  get(key) {
    return JSON.parse(localStorage.getItem("user"))[key];
  },
  all() {
    return JSON.parse(localStorage.getItem("user"));
  },
  reset(value) {
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...this.all(),
        name: value,
      })
    );
  },
};
