const transactionRoute = [
  {
    path: "/:appId/transactions",
    name: "transactions.transactions",
    component: () => import("@/views/transaction/pages/TransactionPage.vue"),
    meta: { title: "Transaction" },
  },
  // {
  //   path: "/:appId/transactions/manage/transaction-list",
  //   name: "transactions.transactions.transaction-list",
  //   component: () => import("@/views/transaction/pages/TransactionPage.vue"),
  //   meta: { title: "Transaction" },
  // },
  // {
  //   path: "/:appId/transactions/manage/booking-list",
  //   name: "transactions.transactions.bookings-list",
  //   component: () => import("@/views/transaction/pages/TransactionPage.vue"),
  //   meta: { title: "Transaction" },
  // },
  {
    path: "/:appId/transactions/manage/collected-response",
    name: "transactions.transactions.collected-response-list",
    component: () =>
      import("@/views/transaction/pages/CollectedResponsePage.vue"),
    meta: { title: "Collected Response" },
  },
  // {
  //   path: "/:appId/transactions/manage/customer-form",
  //   name: "transactions.transactions.custom-form-list",
  //   component: () => import("@/views/transaction/pages/TransactionPage.vue"),
  //   meta: { title: "Custom Form" },
  // },
];

export default transactionRoute;
