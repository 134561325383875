<template>
  <div class="card card-animate">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <div class="avatar-sm flex-shrink-0">
          <span class="avatar-title bg-light text-primary rounded-circle fs-3">
            <slot name="image"></slot>
            <!-- <i class="ri-money-dollar-circle-fill align-middle"></i> -->
          </span>
        </div>
        <div class="flex-grow-1 ms-3">
          <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">
            {{ label }}
          </p>
          <div v-if="loading">
            <b-spinner
              label="Spinning"
              variant="primary"
              style="width: 1.2rem; height: 1.2rem"
            ></b-spinner>
          </div>
          <h4 class="mb-0" v-else>
            <span class="counter-value" :data-target="targetValue">
              {{ value }}
            </span>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toString } from "lodash";
export default {
  name: "WidgetCardImageOne",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "0",
    },
    // imagePath: {
    //   type: String,
    //   default: "icons/add.svg",
    // },
    label: {
      type: String,
      default: "label",
    },
  },
  computed: {
    targetValue() {
      let val = toString(this.value);
      let data = val.replace(/^\D+/g, "");
      return data;
    },
  },
};
</script>
