import * as Types from "./mutation-types";

export default {
  [Types.SET_LOADING]: (state, _) => {
    console.log(_);
    state.isLoading = !state.isLoading;
  },
  [Types.SET_ERROR]: (state, _) => {
    console.log(_);
    state.isError = !state.isError;
  },
  [Types.RECEIVE_FETCH_TRASHBIN_VOICE_REPLACE_SUCCESS]: (state, payload) =>
    (state.trashbin = payload),
};
