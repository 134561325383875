<template>
  <b-button
    class="btn"
    type="button"
    :disabled="loading || disabled"
    :class="[buttonClass, sizeclass, customClass]"
    :variant="variant"
    @click="handleClick"
  >
    <div v-if="loading" class="d-flex align-items-center">
      <span
        class="spinner-border spinner-border-sm flex-shrink-0 me-2"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </span>
      <span class="flex-grow-1">Loading</span>
    </div>
    <slot v-else></slot>
  </b-button>
</template>
<script>
export default {
  name: "BaseButton",
  props: {
    theme: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "md",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClass() {
      return `btn-${this.theme}`;
    },
    sizeclass() {
      return `btn-${this.size}`;
    },
  },
  methods: {
    handleClick() {
      // this.$emit("click"); // this make button trigger twice
      this.$emit("onClick");
    },
  },
};
</script>
<style lang="scss" scoped>
// refer to src/styles/scss/component/button.scss
</style>
