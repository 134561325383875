import * as Types from "./mutation-types";

export default {
  [Types.SET_LOADING]: (state) => (state.isLoading = !state.isLoading),
  [Types.SET_ERROR]: (state) => (state.isError = !state.isError),
  [Types.RECEIVE_FETCH_ENTITIES_SUCCESS]: (state, payload) =>
    (state.entities = payload),
  [Types.RECEIVE_DETAIL_ENTITIES_SUCCESS]: (state, payload) =>
    (state.detailEntities = payload),
  [Types.RECEIVE_FETCH_TRASHBIN_ENTITIES_SUCCESS]: (state, payload) =>
    (state.trashbin = payload),
};
