const Types = {
  SET_DETAIL: "SET_DETAIL",
  SET_LOADING: "SET_LOADING",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_MENU: "SET_MENU",
  SET_SUBMENU: "SET_SUBMENU",
  UPDATE_MODULES: "UPDATE_MODULES",
};

const state = {
  loading: false,
  error: null,
  menus: [],
  submenus: [],
};

const getters = {
  getMenus(state) {
    return state.menus;
  },
  getSubmenus(state) {
    return state.submenus;
  },
};

const mutations = {
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.SET_DETAIL]: (state, payload) => (state.detail = payload),
  [Types.SET_MENU]: (state, payload) => (state.menus = payload),
  [Types.SET_SUBMENU]: (state, payload) => (state.submenus = payload),
};

const actions = {
  async getMenus(context) {
    context.commit(Types.SET_LOADING, true);
    try {
      const response = await this.$axiosNoLoading.get("api/menus");
      let data = response.data;
      data.forEach(function (element) {
        element.enable = false;
        if (element.submenus.length > 0) {
          element.submenus.forEach(function (e) {
            e.enable = false;
          });
        }
      });
      console.log("data", data);
      context.commit(Types.SET_MENU, data);
    } catch (err) {
      context.commit(Types.ERROR_SERVICE, err);
    }
    context.commit(Types.SET_LOADING, false);
  },
  setSubmenus(context, payload) {
    context.commit(Types.SET_SUBMENU, payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
