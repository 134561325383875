import { BNIBlockService } from "@/services";

const Types = {
  ADD_BLOCK_SUCCESS: "ADD_BLOCK_SUCCESS",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_LOADING: "SET_LOADING",
};

const state = {
  data: [],
  error: null,
  loading: false,
};

const getters = {
  get(state) {
    return state.data;
  },
  error(state) {
    return state.error;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  [Types.ADD_BLOCK_SUCCESS]: (state, payload) => (state.data = payload),
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.ERROR_SERVICE]: (state, payload) => (state.error = payload),
};

const actions = {
  async addBlockAccess({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await BNIBlockService.addBlockAccessRequest(data);
      commit(Types.SET_LOADING, false);
      return response;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
      commit(Types.SET_LOADING, false);
    }
  },
  async approveBlockAccess({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await BNIBlockService.approveBlockAccessRequest(data);
      commit(Types.SET_LOADING, false);
      return response;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
      commit(Types.SET_LOADING, false);
    }
  },
  async unBlockAccess({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await BNIBlockService.unBlockAccess(data);
      commit(Types.SET_LOADING, false);
      return response;
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
  },
  async blockTransactionRequest({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await BNIBlockService.blockTransaction(data);
      commit(Types.SET_LOADING, false);
      return response;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
      commit(Types.SET_LOADING, false);
    }
  },
  async approveBlockTransaction({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await BNIBlockService.approveBlockTransaction(data);
      commit(Types.SET_LOADING, false);
      return response;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
      commit(Types.SET_LOADING, false);
    }
  },
  async unBlockTransaction({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await BNIBlockService.unBlockTransaction(data);
      commit(Types.SET_LOADING, false);
      return response;
    } catch (err) {
      commit(Types.SET_LOADING, false);
      commit(Types.ERROR_SERVICE, err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
