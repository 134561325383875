import * as Types from "./mutation-types";
import { httpClientBot } from "@/libraries";
// // import notification from "@/helpers/EventBus";

export const setLoading = ({ commit }) => commit(Types.SET_LOADING);
export const setError = ({ commit }) => commit(Types.SET_ERROR);

export const receiveCreateEntitiesSuccess = ({ commit }) =>
  commit(Types.RECEIVE_CREATE_ENTITIES_SUCCESS);

export const receiveDetailEntitiesSuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_DETAIL_ENTITIES_SUCCESS, payload);

export const receiveFetchTrashbinEntitiesSuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_FETCH_TRASHBIN_ENTITIES_SUCCESS, payload);

export const createEntities = ({ dispatch }, payload) => {
  console.log(payload);
  dispatch("setLoading");
  return httpClientBot
    .post("/entities", {
      entityValues: payload.listValues,
      entity_name: payload.name,
      entity_parseMethod: payload.parseMethod,
    })
    .then((_) => {
      console.log(_);
      // this.emitter.emit("flash-success","Success create entities");
    })
    .catch((error) => {
      console.log(error);
      dispatch("setError");
      // this.emitter.emit("flash-error","Failed create entities");
    })
    .finally((_) => {
      console.log(_);
      dispatch("setLoading");
    });
};

export const deleteEntities = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .delete(`/entities/deleteByName?entityCheckBox=["${payload.entity_name}"]`)
    .then((_) => {
      console.log(_);
      this.emitter.emit("flash-success", "Success delete entities");
    })
    .catch((error) => {
      console.log(error);
      dispatch("setError");
      this.emitter.emit("flash-error", "Failed delete entities");
    })
    .finally((_) => {
      console.log(_);
      dispatch("setLoading");
    });
};

export const detailEntities = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .get(`/entities/values/${payload.entity_name}`)
    .then((response) => response.data)
    .catch((error) => console.log(error), dispatch("setError"))
    .finally(() => {
      dispatch("setLoading");
    });
};

export const editEntities = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .put("/entities/values/edit", payload)
    .then((response) => {
      console.log(response);
    })
    .catch((error) => console.log(error), dispatch("setError"))
    .finally(() => {
      dispatch("setLoading");
    });
};

export const fetchTrashbin = ({ dispatch }) => {
  dispatch("setLoading");
  return httpClientBot
    .get("/entities/trash")
    .then((response) =>
      dispatch("receiveFetchTrashbinEntitiesSuccess", response.data)
    )
    .catch((error) => console.log(error), dispatch("setLoading"))
    .finally((_) => console.log(_), dispatch("setLoading"));
};

export const restoreEntities = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .post(`entities/${payload}/restore`)
    .then((response) => {
      console.log(response);
      this.emitter.emit("flash-success", "Success restore entities");
    })
    .catch((error) => {
      console.log(error);
      dispatch("setError");
      this.emitter.emit("flash-error", "Failed restore entities");
    })
    .finally((_) => console.log(_), dispatch("setLoading"));
};

export const massDeleteEntities = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .delete(`/entities/delete?entityCheckBox=["${payload}"]`)
    .then((_) => {
      console.log(_);
      this.emitter.emit("flash-success", "Success delete entities");
    })
    .catch((error) => {
      console.log(error);
      dispatch("setError");
      this.emitter.emit("flash-error", "Failed delete entities");
    })
    .finally((_) => {
      console.log(_);
      dispatch("setLoading");
    });
};
