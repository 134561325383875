import state from "./state";
import mutations from "./mutation";
import * as getters from "./getter";
import * as actions from "./action";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
