<template>
  <div class="card card-body">
    <div class="text-end dropdown" style="position: absolute; right: 20px">
      <div class="form-check form-switch form-switch-right form-switch-md">
        <!-- <edit button /> -->
        <span class="to-pointer" @click="$emit('edit-on-click')">
          <i class="ri-edit-line text-mute me-2"></i>
        </span>
        <!-- <delete button /> -->
        <span class="to-pointer" @click="$emit('delete-on-click')">
          <i class="ri-delete-bin-6-line text-danger"></i>
        </span>
      </div>
    </div>

    <div class="d-flex mb-4 align-items-center">
      <div class="flex-shrink-0">
        <div class="avatar-xs">
          <div class="avatar-title rounded bg-primary fs-14">
            <i :class="icon" style="font-size: 1.6em"></i>
          </div>
        </div>
      </div>
      <div class="flex-grow-1 ms-2">
        <h5 class="card-title mb-1">
          {{ truncateText(title, "25") }}
        </h5>
      </div>
    </div>

    <span class="card-timestamp text-muted">
      Added on {{ formatDate(created_at, "D MMM, Y LT") }}
    </span>
    <span class="card-timestamp text-muted mb-3">
      Last update {{ formatDate(updated_at, "D MMM, Y LT") }}
    </span>
    <base-button
      :class="{
        'btn-soft-danger': status,
        'btn-soft-secondary': !status,
      }"
      class="btn btn-sm"
      @click="$emit('status-on-click')"
      >{{ status ? "Deactivate" : "Activate" }}</base-button
    >
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    created_at: {
      type: String,
      default: "",
    },
    updated_at: {
      type: String,
      default: "",
    },
    status: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
};
</script>
