import { httpClientOmni } from "@/libraries";
import { ChannelService, FollowUpService } from "@/services";
import Hashids from "hashids";
const Types = {
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
  SET_CHANNEL: "SET_CHANNEL",

  SET_ACTIVE_STORY: "SET_ACTIVE_STORY",
  SET_ACTIVE_STORY_ID: "SET_ACTIVE_STORY_ID",
};

const state = {
  channels: [],
  error: null,
  loading: false,

  activeStoryId: null,
  onActiveStory: [],
};

const getters = {
  loading: (state) => state.loading,
  error: (state) => state.error,
  channels: (state) => state.channels,
};

const mutations = {
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.SET_ERROR]: (state, payload) => (state.error = payload),
  [Types.SET_CHANNEL]: (state, payload) => (state.channels = payload),
  [Types.SET_ACTIVE_STORY]: (state, payload) => (state.onActiveStory = payload),
  [Types.SET_ACTIVE_STORY_ID]: (state, payload) =>
    (state.activeStoryId = payload),
};

const actions = {
  async getChannels({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await ChannelService.getV3Channel();
      commit(Types.SET_CHANNEL, response.data);
      return response;
    } catch (error) {
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async create({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await FollowUpService.create(payload);
      return response;
    } catch (error) {
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async update({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await FollowUpService.update(payload);
      return response;
    } catch (error) {
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async delete({ commit }, id) {
    commit(Types.SET_LOADING, true);
    try {
      let hashids = new Hashids("", 6);
      let hashid = hashids.encode(id);
      const response = await FollowUpService.delete(hashid);
      return response;
    } catch (error) {
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },

  async getActivityStory({ commit, state }, { storyId, refresh = false }) {
    if (
      !state.onActiveStory.length ||
      state.activeStoryId !== storyId ||
      refresh
    ) {
      commit(Types.SET_ACTIVE_STORY_ID, storyId);

      const req = await httpClientOmni.get(`follow-up/${storyId}/story`);
      if (req.status === 200) {
        const res = req.data.data.map((r) => ({
          ...r,
          run_at_second_time: r.run_at_second_time.toString(),
        }));

        commit(Types.SET_ACTIVE_STORY, res);
        return res;
      }
      return [];
    }

    return state.onActiveStory;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
