import { httpClientOmni } from "@/libraries";
export default {
  async getTemplateByIntegrationId(integrationId) {
    return await httpClientOmni.get("template-name", {
      params: {
        integrationId: integrationId,
      },
    });
  },
  async v3GetTemplatePaginate(payload) {
    return await httpClientOmni.get("v3/hsm-template", payload);
  },
  async deleteTemplate(id) {
    return await httpClientOmni.delete(`hsm-template/${id}`);
  },
  async exportCsvTemplate(payload) {
    return await httpClientOmni.post(`v3/hsm-template/export-csv`, payload, {
      responseType: "blob",
    });
  },
  async exportPdfTemplate(payload) {
    return await httpClientOmni.post(`v3/hsm-template/export-pdf`, payload);
  },
  async createHsmTemplate(payload) {
    return await httpClientOmni.post("hsm-template", payload);
  },
};
