<template>
  <div
    class="observer p-2"
    :id="id"
    :class="triggerId ? 'updated' : 'updating'"
  >
    <span ref="trigger"></span>
    <slot name="loading"></slot>
    <slot name="no-more"></slot>
    <slot name="no-result"></slot>
    <slot name="error"></slot>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
export default {
  name: "BaseObserver",
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          root: null,
          // root: document.querySelector("#chat-user-list"),
          rootMargin: "20px",
          threshold: 0,
        };
      },
    },
    triggerId: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      debounce,
      observer: null,
    };
  },
  // watch: {
  //   triggerId() {
  //     this.debounceObserver();
  //   },
  // },
  mounted() {
    this.debounceObserver();
  },
  updated() {
    debounce(() => {
      this.debounceObserver();
    }, 800);
  },
  unmounted() {
    if (this.observer !== null) {
      try {
        this.observer.disconnect();
      } catch (error) {
        console.log("disconnect observer", error);
      }
    }
  },
  methods: {
    debounceObserver: debounce(function debounceCallback() {
      this.addObserver();
    }, 800),
    async addObserver() {
      try {
        await this.$nextTick();
        this.observer = new IntersectionObserver((entries) => {
          // this.handleIntersect(entries[0]);
          if (entries && entries[0].isIntersecting) {
            this.$emit("intersect");
          }
        }, this.options);
        this.observer.observe(this.$refs.trigger);
      } catch (error) {
        console.log("observer error", error);
      }
    },
    handleIntersect(entry) {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect");
      }
    },
  },
};
</script>
