import { httpClientOmni } from "@/libraries";

export default {
  getLivechatTraffics(payload) {
    return httpClientOmni.post(`/dashboard/sla/total-live-chat-status`, {
      start: payload.start,
      end: payload.end,
      status: payload.status,
    });
  },
  getAgentTraffics(payload) {
    return httpClientOmni.get(`/dashboard/sla/agent-status-activity`, {
      params: {
        start: payload.start,
        end: payload.end,
      },
    });
  },
  getSla(payload) {
    return httpClientOmni.get(`/dashboard/sla/sla-all`, {
      params: {
        start: payload.start,
        end: payload.end,
        channelId: payload.channelId,
      },
    });
  },
  getAht(payload) {
    return httpClientOmni.get(`/dashboard/sla/aht-all`, {
      params: {
        start: payload.start,
        end: payload.end,
        channelId: payload.channelId,
      },
    });
  },
};
