export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";

export const RECEIVE_FETCH_ENTITIES_SUCCESS = "RECEIVE_FETCH_ENTITIES_SUCCESS";
export const RECEIVE_CREATE_ENTITIES_SUCCESS =
  "RECEIVE_CREATE_ENTITIES_SUCCESS";

export const RECEIVE_DETAIL_ENTITIES_SUCCESS =
  "RECEIVE_DETAIL_ENTITIES_SUCCESS";
export const RECEIVE_FETCH_TRASHBIN_ENTITIES_SUCCESS =
  "RECEIVE_FETCH_TRASHBIN_ENTITIES_SUCCESS";
