import { httpClientOmni, httpClientAuth } from "@/libraries";

export default {
  async getDetail(param) {
    const response = await httpClientOmni.get(`role/${param}`);
    return response;
  },
  async getModules() {
    const response = await httpClientOmni.get("module/get-all");
    return response;
  },
  async v3GetModules() {
    const response = await httpClientOmni.get("v3/module/get-all");
    return response;
  },
  getAccessibilities() {
    return httpClientOmni.get("v3/accessibility/get-role");
  },
  async getRolePaginate(data) {
    const response = await httpClientOmni.get(`v3/role-paginate`, {
      params: {
        data,
      },
    });
    return response;
  },
  async insertDefaultRole(data) {
    const response = await httpClientAuth.post(
      "/role/insert-default-role-new-platfrom",
      {
        ...data,
      }
    );
    return response;
  },
  async insertDefaultV3Role(data) {
    const response = await httpClientAuth.post("/v3/role/insert-default-role", {
      appId: data.appId,
      decodedUserId: data.decodedUserId,
    });
    return response;
  },
};
