import * as Types from "./mutation-types";
import { httpClientBot } from "@/libraries";
// // import notification from "@/helpers/EventBus";

export const setLoading = ({ commit }) => commit(Types.SET_LOADING);
export const setError = ({ commit }) => commit(Types.SET_ERROR);

export const receiveFetchTrashbinSynonymSuccess = ({ commit }, payload) =>
  commit(Types.RECEIVE_FETCH_TRASHBIN_SYNONYM_SUCCESS, payload);

export const createSynonym = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .post("/synonyms", payload)
    .then(() =>
      this.emitter.emit("flash-success", "Successfully create synonym")
    )
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};

export const updateSynonym = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .put(`/synonyms/${payload.hashed_id}`, payload)
    .then(() => this.emitter.emit("flash-success", "Successfully edit synonym"))
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};

export const deleteSynonym = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .delete(`/synonyms/?synonymCheckBox=["${payload}"]`)
    .then(() =>
      this.emitter.emit("flash-success", "Successfully delete synonym")
    )
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};

export const fetchTrashbin = ({ dispatch }) => {
  dispatch("setLoading");
  return httpClientBot
    .get(`/synonyms/trash`)
    .then((response) =>
      dispatch("receiveFetchTrashbinSynonymSuccess", response.data)
    )
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};

export const restoreSynonym = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .post(`/synonyms/${payload}/restore`, payload)
    .then(() =>
      this.emitter.emit("flash-success", "Successfully restore synonym")
    )
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};

export const massDeleteSynonym = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .delete(`/synonyms/?synonymCheckBox=["${payload}"]`)
    .then(() =>
      this.emitter.emit("flash-success", "Successfully delete synonym")
    )
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};
