export default () => ({
  segments: [],
  isLoading: false,
  hasError: false,
  columns: [
    {
      name: "name",
    },
    {
      name: "nickname",
    },
    {
      name: "gender",
    },
    {
      name: "phone",
    },
    {
      name: "email",
    },
    {
      name: "location",
    },
  ],
  conditions: [
    {
      name: "equals",
    },
    {
      name: "not equals",
    },
    {
      name: "contain",
    },
    {
      name: "not contain",
    },
  ],
});
