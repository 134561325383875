export default () => ({
  interactions: [],
  functionlistApi: [],
  functionlistFunction: [],
  entities: {},
  sysEntities: [],
  channels: [],
  storyGroup: [],
  story: {},
  isError: false,
  mergedEntities: [],
  stories: [],
  interactionList: [],
  isLoading: false,
  selectedInteraction: {
    input_phrase: [],
    responses: [],
    parameters: [],
  },
  conditionList: [
    "Filled",
    "Equal",
    "Not equal",
    "Empty",
    "Contains",
    "Not contains",
    "Greater than",
    "Less than",
    "Greater than or equal",
    "Less than or equal",
  ],
  askCountList: ["1", "2", "3", "4", "untilFulfilled"],
  requireList: ["yes", "no"],
  onFulfilledList: [
    "Stay at Current Interaction",
    "Next Interaction",
    "End Story",
  ],
});
